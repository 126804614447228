_cruxUtils._cruxLocale = {
"crux.no.bc.message":"\u05d0\u05d9\u05df \u05de\u05d1\u05d8 \u05db\u05d5\u05dc\u05dc \u05d6\u05de\u05d9\u05df. \u05dc\u05d7\u05e5 \u05e2\u05dc \u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05db\u05d3\u05d9 \u05dc\u05d4\u05e6\u05d9\u05d2 \u05e4\u05e8\u05d8\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd",
"crm.appointments.status.markcompleted1": "\u05e1\u05de\u05df \u05db\u05d4\u05d5\u05e9\u05dc\u05dd",
"crm.mass.actions.all.selected": "\u05e0\u05d1\u05d7\u05e8\u05d5 <span class=\"dIB vaM\">\u05db\u05dc </span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'>{0}</span><span class=\"dIB vaM\"> {1} \u05d1\u05ea\u05e6\u05d5\u05d2\u05d4 \u05d6\u05d5.</span>",
"crm.module.selectall": "\u05d1\u05d7\u05e8 \u05db\u05dc {0} \u05d1\u05ea\u05e6\u05d5\u05d2\u05d4 \u05d6\u05d5",
"records": "\u05e8\u05e9\u05d5\u05de\u05d5\u05ea",
"crm.record.not.accessible": "\u05e8\u05e9\u05d5\u05de\u05d4",
"crm.view.calls.recording.err.msg": "\u05d0\u05d9\u05df \u05dc\u05d4\u05e7\u05dc\u05d8\u05d4 \u05d4\u05e7\u05d5\u05dc\u05d9\u05ea \u05db\u05ea\u05d5\u05d1\u05ea URL \u05e9\u05dc \u05e9\u05de\u05e2 \u05d4\u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05e9\u05de\u05e2\u05d4.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} \u05e0\u05d1\u05d7\u05e8\u05d4.</span>",
"totalrecords": "\u05e1\u05da \u05d4\u05db\u05dc \u05e8\u05e9\u05d5\u05de\u05d5\u05ea",
"crm.listview.options.text.clip": "\u05de\u05e7\u05d8\u05e2 \u05d8\u05e7\u05e1\u05d8",
"crm.listview.options.text.wrap": "\u05e2\u05d8\u05d5\u05e3 \u05d8\u05e7\u05e1\u05d8",
"crm.listview.options.reset.width": "\u05d0\u05e4\u05e1 \u05d2\u05d5\u05d3\u05dc \u05e2\u05de\u05d5\u05d3\u05d4",
"crm.listview.options.manage": "\u05e0\u05d4\u05dc \u05e2\u05de\u05d5\u05d3\u05d5\u05ea",
"crm.listview.customview.locked": "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05ea\u05d0\u05d9\u05dd \u05d0\u05ea \u05d4\u05e2\u05de\u05d5\u05d3\u05d5\u05ea \u05d1\u05d0\u05d5\u05e4\u05df \u05d0\u05d9\u05e9\u05d9 \u05de\u05e9\u05d5\u05dd \u05e9\u05ea\u05e6\u05d5\u05d2\u05d4 \u05d6\u05d5 \u05e0\u05e2\u05d5\u05dc\u05d4",
"crm.api.apidashboard.SortBy": "\u05de\u05d9\u05d9\u05df \u05dc\u05e4\u05d9",
"crm.privacy.listview.consent.locked": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05e0\u05e0\u05e2\u05dc\u05d4 \u05d1\u05e4\u05e0\u05d9 \u05e2\u05d9\u05d1\u05d5\u05d3",
"crm.label.reschedule.call": "\u05ea\u05d6\u05de\u05df \u05de\u05d7\u05d3\u05e9 \u05d0\u05ea {0}",
"crm.button.edit": "\u05e2\u05e8\u05d5\u05da",
"Delete": "\u05de\u05d7\u05e7",
"crm.appointments.status.markcancel": "\u05d1\u05d9\u05d8\u05d5\u05dc  {0}",
"crm.appointments.status.markreschedule": "\u05ea\u05d6\u05de\u05df \u05de\u05d7\u05d3\u05e9 \u05d0\u05ea {0}",
"crm.label.cancel.call": "\u05d1\u05d9\u05d8\u05d5\u05dc  {0}",
"crm.label.mark.completed": "\u05e1\u05de\u05df \u05db\u05d4\u05d5\u05e9\u05dc\u05dd",
"crm.listview.customview.locked": "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05ea\u05d0\u05d9\u05dd \u05d0\u05ea \u05d4\u05e2\u05de\u05d5\u05d3\u05d5\u05ea \u05d1\u05d0\u05d5\u05e4\u05df \u05d0\u05d9\u05e9\u05d9 \u05de\u05e9\u05d5\u05dd \u05e9\u05ea\u05e6\u05d5\u05d2\u05d4 \u05d6\u05d5 \u05e0\u05e2\u05d5\u05dc\u05d4",
"pf.deleted.state": "\u05de\u05e6\u05d1 \u05de\u05d7\u05d5\u05e7",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "\u05e1\u05d5\u05e0\u05db\u05e8\u05df \u05e2\u05dd {0}",
"crm.schedules.prefix.in": "\u05d1",
"crm.record.record.locked.other": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05e0\u05e2\u05d5\u05dc\u05d4 \u05e2\u05d1\u05d5\u05e8 \u05de\u05e9\u05ea\u05de\u05e9\u05d9 \u05d4\u05e4\u05e8\u05d5\u05e4\u05d9\u05dc \u05d4\u05d0\u05d7\u05e8\u05d9\u05dd.",
"crm.record.lock.record.locked": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05e0\u05e2\u05d5\u05dc\u05d4.",
"crm.approvalProcess.label.waitingForApproval": "\u05de\u05de\u05ea\u05d9\u05df \u05dc\u05d0\u05d9\u05e9\u05d5\u05e8",
"crm.reviewprocess.record.review.pending": "\u05de\u05de\u05ea\u05d9\u05df \u05dc\u05e1\u05e7\u05d9\u05e8\u05d4",
"crm.approvalProcess.label.waitingForFindAndMerge": "\u05de\u05d9\u05d6\u05d5\u05d2 \u05db\u05e4\u05d9\u05dc\u05d5\u05d9\u05d5\u05ea \u05de\u05ea\u05d1\u05e6\u05e2 \u05db\u05e8\u05d2\u05e2.",
"crm.privacy.listview.consent.locked": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05e0\u05e0\u05e2\u05dc\u05d4 \u05d1\u05e4\u05e0\u05d9 \u05e2\u05d9\u05d1\u05d5\u05d3",
"crm.zia.vision.processing": "Zia \u05d1\u05d5\u05d3\u05e7\u05ea \u05d0\u05ea \u05d4\u05ea\u05de\u05d5\u05e0\u05d4(\u05d5\u05ea).",
"crm.zia.vision.rejected.msg": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05e0\u05d3\u05d7\u05ea\u05d4 \u05d1\u05e9\u05dc \u05db\u05e9\u05dc \u05d1\u05d1\u05d3\u05d9\u05e7\u05ea \u05ea\u05de\u05d5\u05e0\u05d4.",
"crm.zia.vision.record.failure.msg": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05de\u05de\u05ea\u05d9\u05e0\u05d4 \u05dc\u05d0\u05d9\u05e9\u05d5\u05e8  \u05d1\u05e9\u05dc \u05db\u05e9\u05dc \u05d1\u05d1\u05d3\u05d9\u05e7\u05ea \u05d4\u05ea\u05de\u05d5\u05e0\u05d4.",
"crm.alert.max.cvcols.reached": "\u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea\u05da \u05dc\u05d1\u05d7\u05d5\u05e8 \u05e2\u05d3 {0} \u05e2\u05de\u05d5\u05d3\u05d5\u05ea.",
"crm.label.small.tags": "\u05ea\u05d2\u05d9\u05d5\u05ea",
"crm.label.edit.module": "\u05e2\u05e8\u05d5\u05da {0}",
"crm.social.integ.no.result": "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05ea\u05d5\u05e6\u05d0\u05d5\u05ea",
"custommodule.crmfield.fl.name": "\u05e9\u05dd {0}",
"Appointment": "\u05e4\u05d2\u05d9\u05e9\u05d4",
"crm.button.create.call": "\u05e6\u05d5\u05e8 \u05e9\u05d9\u05d7\u05d4",
"crm.module.create": "\u05e6\u05d5\u05e8 {0}",
"crm.customview.activty.badge": "\u05ea\u05d2 \u05e4\u05e2\u05d9\u05dc\u05d5\u05ea",
"crm.button.callnow": "\u05d4\u05ea\u05e7\u05e9\u05e8 \u05db\u05e2\u05ea",
"crm.manualcalllist.complete.call": "\u05e8\u05e9\u05d5\u05dd \u05e9\u05d9\u05d7\u05d4",
"crm.label.schedule.call": "\u05ea\u05d0\u05dd \u05e9\u05d9\u05d7\u05d4",
"crm.filter.label.replied":"\u05d4\u05d2\u05d9\u05d1",
"bytes": "\u05d1\u05ea\u05d9\u05dd",
"crm.field.label.user.lookup": "\u05de\u05e9\u05ea\u05de\u05e9",
"crm.translation.refresh.cases": "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05d4\u05d9\u05d4 \u05dc\u05d4\u05e9\u05dc\u05d9\u05dd \u05d0\u05ea \u05d4\u05e4\u05e2\u05d5\u05dc\u05d4 \u05e9\u05d0\u05ea\u05d4 \u05de\u05e0\u05e1\u05d4 \u05dc\u05d1\u05e6\u05e2. \u05e8\u05e2\u05e0\u05df \u05d0\u05ea \u05d4\u05d3\u05e3 \u05d5\u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1.",
"crm.project.sync.notes.info": "\u05ea\u05d5\u05e6\u05d2\u05e0\u05d4 \u05e8\u05e7 \u05d4\u05e2\u05e8\u05d5\u05ea \u05d4\u05de\u05e9\u05d9\u05de\u05d5\u05ea \u05e9\u05dc \u05d4\u05e4\u05e8\u05d5\u05d9\u05e7\u05d8\u05d9\u05dd \u05d4\u05de\u05e9\u05d5\u05d9\u05db\u05d9\u05dd",
"crux.note.content.limit.warning.msg":"\u05de\u05d5\u05ea\u05e8 \u05dc\u05e9\u05de\u05d5\u05e8 {0} \u05ea\u05d5\u05d5\u05d9\u05dd \u05e8\u05e7 \u05d1\u05ea\u05d5\u05db\u05df \u05e9\u05dc \u05d4\u05e2\u05e8\u05d5\u05ea",
"crux.note.formatting.options":"\u05d0\u05e4\u05e9\u05e8\u05d5\u05d9\u05d5\u05ea \u05e2\u05d9\u05e6\u05d5\u05d1",
"crm.label.required": "\u05e0\u05d3\u05e8\u05e9",
"crm.filter.header.secton.system": "\u05e1\u05d9\u05e0\u05d5\u05e0\u05d9\u05dd \u05de\u05d5\u05d2\u05d3\u05e8\u05d9 \u05de\u05e2\u05e8\u05db\u05ea",//NO I18N
"crm.filter.header.secton.fields": "\u05e1\u05d9\u05e0\u05d5\u05df \u05dc\u05e4\u05d9 \u05e9\u05d3\u05d5\u05ea",//NO I18N
"crux.condition.not.belongs.to":"\u05d0\u05d9\u05e0\u05d4 \u05e9\u05d9\u05d9\u05db\u05ea \u05d0\u05dc {0}",
"crm.project.sync.notes.info": "\u05ea\u05d5\u05e6\u05d2\u05e0\u05d4 \u05e8\u05e7 \u05d4\u05e2\u05e8\u05d5\u05ea \u05d4\u05de\u05e9\u05d9\u05de\u05d5\u05ea \u05e9\u05dc \u05d4\u05e4\u05e8\u05d5\u05d9\u05e7\u05d8\u05d9\u05dd \u05d4\u05de\u05e9\u05d5\u05d9\u05db\u05d9\u05dd",
"crm.sf.permission.no.edit": "\u05d0\u05d9\u05df \u05dc\u05da \u05d4\u05e8\u05e9\u05d0\u05d4 \u05dc\u05e2\u05d3\u05db\u05df \u05d0\u05ea \u05d4\u05e2\u05e8\u05db\u05d9\u05dd.",
"crm.sf.permission.no.create": "\u05d0\u05d9\u05df \u05dc\u05da \u05d4\u05e8\u05e9\u05d0\u05d4 \u05dc\u05d4\u05d5\u05e1\u05d9\u05e3 \u05e2\u05e8\u05db\u05d9\u05dd.",
"crm.sf.permission.no.delete": "\u05d0\u05d9\u05df \u05dc\u05da \u05d4\u05e8\u05e9\u05d0\u05d4 \u05dc\u05de\u05d7\u05d5\u05e7 \u05e2\u05e8\u05db\u05d9\u05dd.",
"crux.note.this.note":"\u05d4\u05e2\u05e8\u05d4 \u05d6\u05d0\u05ea",
"KB": "\u05e7\u05d9\u05dc\u05d5-\u05d1\u05d9\u05d9\u05d8",
"MB": "\u05de\u05d2\u05d4-\u05d1\u05d9\u05d9\u05d8",
"current.logged.in.user.role": "\u05ea\u05e4\u05e7\u05d9\u05d3 \u05d4\u05de\u05e9\u05ea\u05de\u05e9 \u05d4\u05e0\u05de\u05e6\u05d0 \u05d1\u05de\u05e2\u05e8\u05db\u05ea",
"Unit Price": "\u05de\u05d7\u05d9\u05e8 \u05d9\u05d7\u05d9\u05d3\u05d4",
"Qty in Stock": "\u05db\u05de\u05d5\u05ea \u05d1\u05de\u05dc\u05d0\u05d9",
"crm.label.Product Code": "\u05e7\u05d5\u05d3 \u05d4\u05de\u05d5\u05e6\u05e8",
"TotalAfterDiscount": "\u05e1\u05da \u05d4\u05db\u05dc \u05d0\u05d7\u05e8\u05d9 \u05d4\u05e0\u05d7\u05d4",
"crm.lookupfilter.entity.errormsg": "\u05d4{0} \u05d4\u05e0\u05d1\u05d7\u05e8\u05d9\u05dd \u05d0\u05d9\u05e0\u05dd \u05ea\u05d5\u05d0\u05de\u05d9\u05dd \u05dc\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05e9\u05dc \u05d1\u05d3\u05d9\u05e7\u05ea \u05d4\u05de\u05d9\u05d3\u05e2.",
"crm.iamexception.maxlen": "\u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d6\u05d9\u05df \u05d9\u05d5\u05ea\u05e8 \u05de  {0}    \u05ea\u05d5\u05d5\u05d9\u05dd \u05e2\u05d1\u05d5\u05e8  {1}",
"crm.record.locking.permission.denied": "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d1\u05e6\u05e2 \u05e4\u05e2\u05d5\u05dc\u05d4 \u05d6\u05d5 \u05de\u05e9\u05d5\u05dd \u05e9\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05e0\u05e2\u05d5\u05dc\u05d4.",
"List Price": "\u05de\u05d7\u05d9\u05e8 \u05de\u05d7\u05d9\u05e8\u05d5\u05df",
"crm.label.no.more.records": "\u05d0\u05d9\u05df \u05e2\u05d5\u05d3 \u05e8\u05e9\u05d5\u05de\u05d5\u05ea",
"crm.workflow.rule.created.dateAndTime":"{0} \u05d1-{1} \u05d1-{2}",
"crux.new.note.msg":"\u05d1\u05de\u05d4 \u05e2\u05d5\u05e1\u05e7\u05ea \u05d4\u05e2\u05e8\u05d4 \u05d6\u05d5?",
"crm.image.uploaded":"\u05d4\u05ea\u05de\u05d5\u05e0\u05d4 \u05d4\u05d5\u05e2\u05dc\u05ea\u05d4",
"crm.imageupload.drag.drop.here":"\u05d2\u05e8\u05d5\u05e8 \u05d5\u05e9\u05d7\u05e8\u05e8 \u05d0\u05ea \u05d4\u05ea\u05de\u05d5\u05e0\u05d5\u05ea \u05db\u05d0\u05df",
"crm.fileupload.drag.drop.here":"\u05d2\u05e8\u05d5\u05e8 \u05d5\u05e9\u05d7\u05e8\u05e8 \u05d0\u05ea \u05d4\u05e7\u05d1\u05e6\u05d9\u05dd \u05db\u05d0\u05df",
"crm.fileupload.wait.msg":"\u05d0\u05e0\u05d0 \u05d4\u05de\u05ea\u05df \u05e2\u05d3 \u05e9\u05d4\u05e7\u05d1\u05e6\u05d9\u05dd \u05d4\u05e0\u05d1\u05d7\u05e8\u05d9\u05dd \u05d9\u05d5\u05e2\u05dc\u05d5",
"crm.fileupload.attach.fail":"\u05d4\u05e7\u05d5\u05d1\u05e5 \u05d4\u05de\u05e6\u05d5\u05e8\u05e3 \u05e0\u05db\u05e9\u05dc",
"crm.fileupload.support.format":"\u05ea\u05d1\u05e0\u05d9\u05ea \u05e7\u05d5\u05d1\u05e5 \u05ea\u05de\u05d9\u05db\u05d4 (jpeg, jpg, png, pdf) \u05e2\u05d1\u05d5\u05e8 \u05d4\u05d4\u05d3\u05e8\u05db\u05d4 \u05e9\u05dc Zia",
"custmr.prtl.user.role": "\u05de\u05e9\u05ea\u05de\u05e9 \u05e4\u05d5\u05e8\u05d8\u05dc",
"crm.subform.empty.alert.header":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05e9\u05de\u05d5\u05e8 \u05e9\u05d5\u05e8\u05d4 \u05e8\u05d9\u05e7\u05d4",
"crm.crud.lookup.module.inaccessible": "\u05d0\u05d9\u05df \u05dc\u05da \u05d4\u05e8\u05e9\u05d0\u05ea \u05dc\u05d2\u05e9\u05ea \u05d0\u05dc \u05de\u05d5\u05d3\u05d5\u05dc \u05d1\u05d3\u05d9\u05e7\u05ea \u05de\u05d9\u05d3\u05e2 \u05d6\u05d4",
"crm.crud.lookup.inaccessible.record": "\u05d4\u05e8\u05e9\u05d5\u05de\u05d4 \u05d0\u05d9\u05e0\u05d4 \u05e7\u05d9\u05d9\u05de\u05ea \u05d0\u05d5 \u05d0\u05d9\u05e0\u05d4 \u05e0\u05d2\u05d9\u05e9\u05d4 \u05e2\u05d1\u05d5\u05e8\u05da.",
"crm.custombutton.valid.weburl.check": "\u05d0\u05e0\u05d0 \u05d4\u05d6\u05df \u05e2\u05e8\u05da \u05db\u05dc\u05e9\u05d4\u05d5.",
"crm.label.subform.goto.top": "\u05d2\u05e9 \u05dc\u05e8\u05d0\u05e9 \u05d4\u05e8\u05e9\u05d9\u05de\u05d4",
"crm.label.subform.row.show.all": "\u05d4\u05e6\u05d2 \u05d4\u05db\u05dc ({0})",
"cpq.pr.nodiscount": "\u05d0\u05d9\u05df \u05d4\u05e0\u05d7\u05d4 \u05de\u05e9\u05d5\u05d9\u05db\u05ea",
"crm.inventory.lineitem.no.pricebook.new": "\u05d0\u05d9\u05df {0} \u05de\u05e9\u05d5\u05d9\u05db\u05d9\u05dd.",
"crm.label.subform.allowedlimit": "\u05d4\u05d2\u05e2\u05ea \u05dc\u05d2\u05d1\u05d5\u05dc \u05d4\u05de\u05d5\u05ea\u05e8 \u05e9\u05dc {0} \u05e9\u05d5\u05e8\u05d5\u05ea",
"crm.reports.integ.okay": "\u05d1\u05e1\u05d3\u05e8",
"crm.crud.subform.deleted.record": "\u05d4\u05e9\u05d5\u05e8\u05d4(\u05d4\u05e9\u05d5\u05e8\u05d5\u05ea) \u05d4\u05de\u05e2\u05d5\u05d3\u05db\u05e0(\u05d5)\u05ea \u05e9\u05dc \u05ea\u05ea-\u05d4\u05d8\u05d5\u05e4\u05e1 \u05db\u05d1\u05e8 \u05e0\u05de\u05d7\u05e7\u05d4(\u05e0\u05de\u05d7\u05e7\u05d5) \u05e8\u05e2\u05e0\u05df \u05d0\u05ea \u05d4\u05d3\u05e3 \u05d5\u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1.",
"crux.criteria.fieldlabel.valid.check":"\u05d0\u05e0\u05d0 \u05d4\u05d6\u05df \u05ea\u05d5\u05d5\u05d9\u05ea \u05e9\u05d3\u05d4 \u05d7\u05d5\u05e7\u05d9\u05ea",
"crm.subform.delete.confirm.header":"מחק שורה בתת הטופס",
"crm.subform.delete.confirm.msg": "האם אתה בטוח שברצונך למחוק את השורה הנבחרת ב-{0}?",
"crm.subform.delete.tooltip.msg" :"לא ניתן למחוק את השורה. דרוש לפחות ערך של שורה אחת עבור ה-{0}.",
	"crm.condition.belongs.to":"\u05e9\u05d9\u05d9\u05db\u05ea \u05d0\u05dc {0}",//no i18n
"crm.condition.not.belongs.to":"\u05dc\u05d0 \u05e9\u05d9\u05d9\u05db\u05ea \u05d0\u05dc {0}",//no i18n
"crm.orchestration.error.selectvalue": "\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05e2\u05e8\u05da",
	"crm.attach.upload.image":"\u05e6\u05d9\u05e8\u05d5\u05e3 \u05ea\u05de\u05d5\u05e0\u05d5\u05ea",//no i18n
"crm.general.select.photo":"\u05d1\u05d7\u05e8 \u05ea\u05de\u05d5\u05e0\u05d4",//no i18n
"crm.imageupload.wait.msg":"\u05e0\u05d0 \u05d4\u05de\u05ea\u05df \u05e2\u05d3 \u05dc\u05d4\u05e2\u05dc\u05d0\u05ea \u05d4\u05ea\u05de\u05d5\u05e0\u05d4 \u05e9\u05e0\u05d1\u05d7\u05e8\u05d4.",//no i18n
"crm.image.filepicker.desc.draganddrop":"\u05d2\u05e8\u05d5\u05e8 \u05d5\u05e9\u05d7\u05e8\u05e8 \u05ea\u05de\u05d5\u05e0\u05d4",//no i18n
"crm.image.filepicker.desc.browse":"\u05dc\u05d7\u05e5 \u05db\u05d3\u05d9 \u05dc\u05e2\u05d9\u05d9\u05df \u05d1\u05ea\u05de\u05d5\u05e0\u05d5\u05ea...",//no i18n
"crm.attach.upload.userinfo":"\u05d4\u05d2\u05d5\u05d3\u05dc \u05d4\u05db\u05d5\u05dc\u05dc \u05de\u05d5\u05d2\u05d1\u05dc \u05dc- <span class=\"proximas\">{0} MB</span> \u05d0\u05d5 \u05db\u05de\u05d5\u05ea \u05de\u05e8\u05d1\u05d9\u05ea \u05e9\u05dc <span class=\"proximas\">{1}</span> \u05ea\u05de\u05d5\u05e0\u05d5\u05ea.",//no i18n
"crm.image.supported.formats":"\u05ea\u05d1\u05e0\u05d9\u05d5\u05ea \u05e0\u05ea\u05de\u05db\u05d5\u05ea: JPEG, PNG, GIF \u05d5-BMP.",//no i18n
"crm.imageupload.allowed.field.length":"\u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea\u05da \u05dc\u05d4\u05e2\u05dc\u05d5\u05ea \u05db\u05de\u05d5\u05ea \u05de\u05d9\u05e8\u05d1\u05d9\u05ea \u05e9\u05dc {0} \u05ea\u05de\u05d5\u05e0\u05d5\u05ea \u05d1\u05dc\u05d1\u05d3.",//no i18n
"crm.general.crop.and.set":"\u05d7\u05ea\u05d5\u05da \u05d5\u05d4\u05d2\u05d3\u05e8",//no i18n
"crm.attach.option.label":"\u05e6\u05e8\u05e3",//no i18n
"crm.image.crop.and.rotate":"\u05d7\u05ea\u05d5\u05da \u05d5\u05e1\u05d5\u05d1\u05d1",//no i18n
"crm.image.resolution":"\u05e4\u05ea\u05e8\u05d5\u05df",//no i18n
"crm.attachment.size":"\u05d2\u05d5\u05d3\u05dc",//no i18n
"crm.label.close":"\u05e1\u05d2\u05d5\u05e8",//no i18n
"crm.label.previous":"\u05d4\u05e7\u05d5\u05d3\u05dd",//no i18n
"crm.label.next":"\u05d4\u05d1\u05d0",//no i18n
"crm.subform.record.create.maxrow.limit": "{0} \u05d4\u05d5\u05d0 \u05de\u05e1\u05e4\u05e8 \u05d4\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05d4\u05de\u05e8\u05d1\u05d9 \u05d4\u05de\u05d5\u05ea\u05e8 \u05d1-{1}",
"crm.label.subform.addrows": "\u05d4\u05d5\u05e1\u05e3 \u05e9\u05d5\u05e8\u05d4",
"crm.tax.association.check": "\u05d0\u05d9\u05df \u05de\u05d9\u05e1\u05d9\u05dd \u05d4\u05de\u05e9\u05d5\u05d9\u05db\u05d9\u05dd \u05dc\u05de\u05d5\u05e6\u05e8 \u05d6\u05d4.",
	"crm.gallery.inventory.template.discount": "\u05d4\u05e0\u05d7\u05d4",
	"crm.inventory.discount.scheme.range": "\u05d8\u05d5\u05d5\u05d7",
	"Description": "\u05ea\u05d9\u05d0\u05d5\u05e8",
	"crm.inventory.lineitem.no.pricebook": "\u05dc\u05d0 \u05e9\u05d5\u05d9\u05db\u05d5 \u05de\u05d7\u05d9\u05e8\u05d5\u05e0\u05d9 \u05e1\u05e4\u05e7\u05d9\u05dd.",
	"crm.recordImage.previewimage":"\u05ea\u05e6\u05d5\u05d2\u05d4 \u05de\u05e7\u05d3\u05d9\u05de\u05d4 \u05e9\u05dc \u05d4\u05ea\u05de\u05d5\u05e0\u05d4",//no i18n
"crm.FileuploadField.addNewImage":"\u05d4\u05e2\u05dc\u05d4 \u05ea\u05de\u05d5\u05e0\u05d4",//no i18n
"crm.fileuploader.removefile":"\u05d4\u05e1\u05e8",//no i18n
	"voc.gc.configure":"\u05d4\u05d2\u05d3\u05e8 \u05ea\u05e6\u05d5\u05e8\u05d4",//no i18n
"Edit":"\u05e2\u05e8\u05d5\u05da",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "\u05d0\u05e0\u05d0 \u05e1\u05e4\u05e7 \u05e2\u05e8\u05da \u05ea\u05d5\u05d0\u05dd \u05e2\u05d1\u05d5\u05e8 {0}",
	"crm.label.field":"\u05e9\u05d3\u05d4",//no i18n
"crm.label.value":"\u05e2\u05e8\u05da",//no i18n
	"crm.button.clone":"\u05e9\u05db\u05e4\u05dc",//no i18n
	"sentiment.criteria.wrongcriteria":"\u05e2\u05e8\u05da \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05df \u05dc\u05d0 \u05d9\u05e2\u05dc\u05d4 \u05e2\u05dc {0}",//no i18n
"crm.mb.field.common.splc":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05e9\u05ea\u05de\u05e9 \u05d1\u05ea\u05d5\u05d5\u05d9\u05dd \u05de\u05d9\u05d5\u05d7\u05d3\u05d9\u05dd. \u05d4\u05db\u05e0\u05e1 \u05e2\u05e8\u05da \u05d7\u05d5\u05e7\u05d9.",//no i18n
	"crm.view.attachment.download":"\u05d4\u05d5\u05e8\u05d3",//no i18n
"crm.label.view":"\u05d4\u05e6\u05d2",//no i18n
	"crm.label.field.plural":"\u05e9\u05d3\u05d5\u05ea",//no i18n
	"crm.label.in.minutes":"{0} (\u05d1\u05d3\u05e7\u05d5\u05ea)",//no i18n
	"crm.security.roles.list":"\u05e8\u05e9\u05d9\u05de\u05ea \u05ea\u05e4\u05e7\u05d9\u05d3\u05d9\u05dd",//no i18n
"crm.security.roles.lookup.info":"\u05d0\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05de\u05d4\u05e8\u05e9\u05d9\u05de\u05d4 \u05ea\u05e4\u05e7\u05d9\u05d3 \u05d0\u05d7\u05d3.",//no i18n
"crm.territory.addterritory":"\u05d4\u05d5\u05e1\u05e3 \u05d8\u05e8\u05d9\u05d8\u05d5\u05e8\u05d9\u05d4",//no i18n
"crm.title.edit.territory":"\u05e2\u05e8\u05d5\u05da \u05d8\u05e8\u05d9\u05d8\u05d5\u05e8\u05d9\u05d4",//no i18n
"crm.territory.title.assign.territories":"\u05d4\u05e7\u05e6\u05d4 \u05d8\u05e8\u05d9\u05d8\u05d5\u05e8\u05d9\u05d5\u05ea",//no i18n
	"crm.label.context.help":"\u05e2\u05d6\u05e8\u05d4",//no i18n
	"crm.label.from":"\u05de\u05d0\u05ea",//no i18n
"crm.label.to":"\u05d0\u05dc",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u05de\u05ea\u05d0\u05e8\u05d9\u05da",//no i18n
"workflow.option.webhookFailure.toDate":"\u05e2\u05d3 \u05ea\u05d0\u05e8\u05d9\u05da",//no i18n
"crm.custom.field.less.than.equalto":"{0} \u05e6\u05e8\u05d9\u05da \u05dc\u05d4\u05d9\u05d5\u05ea \u05e4\u05d7\u05d5\u05ea \u05d0\u05d5 \u05e9\u05d5\u05d5\u05d4 \u05dc{1}.",//no i18n
	"crm.template.listview.search.no.results":"\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05ea\u05d5\u05e6\u05d0\u05d5\u05ea",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"\u05ea\u05d0\u05e8\u05d9\u05da \u05de \u05dc\u05d0 \u05d9\u05d4\u05d9\u05d4 \u05de\u05d0\u05d5\u05d7\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de\u05ea\u05d0\u05e8\u05d9\u05da \u05e2\u05d3.",//no i18n
	"crm.label.tag.new":"\u05ea\u05d2\u05d9\u05ea \u05d7\u05d3\u05e9\u05d4",//No I18n
	"crm.label.enter.tag":"\u05d4\u05d6\u05df \u05ea\u05d2\u05d9\u05d5\u05ea",//No I18n
	"crux.comboBox.max.limit":"\u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de  {0}   {1}",//NO I18n
	"Administrator":"\u05de\u05e0\u05d4\u05dc",//No I18n
	"Standard":"\u05ea\u05e7\u05e0\u05d9",//No I18n
	"crm.button.add":"\u05d4\u05d5\u05e1\u05e3",//NO I18n
	"crm.label.users":"\u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd", //NO I18n
  "crm.workflow.alert.roles":"\u05ea\u05e4\u05e7\u05d9\u05d3\u05d9\u05dd", //NO I18n
  "crm.security.groups":"\u05e7\u05d1\u05d5\u05e6\u05d5\u05ea", //NO I18n
	"crm.label.available" : "\u05d6\u05de\u05d9\u05df", //NO I18n
	"crm.label.assign.manually" : "\u05d4\u05e7\u05e6\u05d4", //NO I18n
	"crm.globalsearch.option.all": "\u05d4\u05db\u05dc", //NO I18n
	"webform.status.Active":"\u05e4\u05e2\u05d9\u05dc", //NO I18n
	"Inactive":"\u05dc\u05d0 \u05e4\u05e2\u05d9\u05dc", //NO I18n
  "Confirmed":"\u05de\u05d0\u05d5\u05e9\u05e8", //NO I18n
  "crm.user.component.unconfirmed":"\u05dc\u05d0 \u05d0\u05d5\u05e9\u05e8",//no i18n
  "DeletedUser":"\u05e0\u05de\u05d7\u05e7", //NO I18n
  "crm.feed.group.admin":"\u05de\u05e0\u05d4\u05dc \u05de\u05e2\u05e8\u05db\u05ea", //NO I18n
  "crm.ln.lable.current":"\u05e0\u05d5\u05db\u05d7\u05d9", //NO I18n
	"crm.label.selected": "\u05e0\u05d1\u05d7\u05e8",//NO I18n
  "crm.auditlog.user": "\u05de\u05e9\u05ea\u05de\u05e9", //NO I18n
  "cob.role": "\u05ea\u05e4\u05e7\u05d9\u05d3", //NO I18n
  "zoho.email": "\u05d3\u05d5\u05d0\u05f4\u05dc", //NO I18n
  "Profile": "\u05e4\u05e8\u05d5\u05e4\u05d9\u05dc", //NO I18n
	"crm.security.group.users.empty": "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd.", //NO I18n
	"crm.label.picklist.none": "\u05dc\u05dc\u05d0", //NO I18n
	"crm.usrpop.non.selected" : "\u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd \u05e9\u05e0\u05d1\u05d7\u05e8\u05d5",//NO I18n
	"crm.label.notSelected" : "\u05dc\u05d0 \u05e0\u05d1\u05d7\u05e8",//NO I18n
	"AM" : "\u05d1\u05d5\u05e7\u05e8",//NO I18n
	"Call" : "\u05e9\u05d9\u05d7\u05d4",//NO I18n
	"crm.phoneNo.Link.Title" : "\u05d4\u05ea\u05e7\u05e9\u05e8 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea Skype {0}",//NO I18n
	"crm.no.data.found" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0 \u05de\u05d9\u05d3\u05e2.",//NO I18n
	"crm.zti.label.user": "\u05e9\u05dd \u05de\u05e9\u05ea\u05de\u05e9", //NO I18n
	"crm.label.no.options.found" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05d0\u05e4\u05e9\u05e8\u05d5\u05d9\u05d5\u05ea.",//No I18n
	"crm.globalsearch.search.title" : "\u05d7\u05d9\u05e4\u05d5\u05e9",//No I18n
	"None" : "\u05dc\u05dc\u05d0",//No I18n
	"crm.label.criteria.pattern" : "\u05ea\u05d1\u05e0\u05d9\u05ea \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd",//No I18n
	"crm.label.edit.criteria.pattern" : "\u05e2\u05e8\u05d9\u05db\u05ea \u05ea\u05d1\u05e0\u05d9\u05ea",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u05d4\u05e1\u05d5\u05d2\u05e8\u05d9\u05d9\u05dd \u05d4\u05de\u05e8\u05d5\u05d1\u05e2\u05d9\u05dd \u05e9\u05dc \u05d4\u05ea\u05d1\u05e0\u05d9\u05ea \u05dc\u05d0 \u05de\u05ea\u05d0\u05d9\u05de\u05d9\u05dd.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u05de\u05d9\u05e8\u05db\u05d0\u05d5\u05ea \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9\u05d5\u05ea \u05e1\u05d1\u05d9\u05d1 \u05d0\u05d5\u05e4\u05e8\u05d8\u05d5\u05e8(\u05d9\u05dd) \u05ea\u05e0\u05d0\u05d9.",//No I18n
	"crm.criteria.number.notmatch.check" : "\u05d0\u05e0\u05d0 \u05d1\u05d3\u05d5\u05e7 \u05d0\u05ea \u05d4\u05ea\u05d1\u05e0\u05d9\u05ea \u05d1{0}.",//No I18n
	"criteria.error.alert.other.params" : "\u05ea\u05d5\u05db\u05df \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9 \u05d1\u05ea\u05d1\u05e0\u05d9\u05ea \u05d6\u05d0\u05ea.", //No I18n
	"crm.label.search.for.users": "\u05d7\u05e4\u05e9 \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u05ea\u05d1\u05e0\u05d9\u05ea \u05d4\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05dc\u05d0 \u05ea\u05d5\u05d0\u05de\u05ea \u05dc\u05ea\u05e0\u05d0\u05d9\u05dd \u05e9\u05d1\u05d7\u05e8\u05ea.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u05ea\u05d1\u05e0\u05d9\u05ea \u05d4\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05dc\u05d0 \u05ea\u05d5\u05d0\u05de\u05ea \u05dc\u05ea\u05e0\u05d0\u05d9\u05dd \u05e9\u05d1\u05d7\u05e8\u05ea.", //No I18n
	"and" : "\u05d5", //No I18n
	"or" : "\u05d0\u05d5", //No I18n
	"crm.label.or" : "\u05d0\u05d5", //No I18n
	"crm.label.and" : "\u05d5", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u05d0\u05e0\u05d0 \u05d4\u05db\u05e0\u05e1 \u05ea\u05d5\u05d5\u05d9\u05ea \u05e9\u05d3\u05d4 \u05d7\u05d5\u05e7\u05d9\u05ea \u05d1\u05e9\u05d5\u05e8\u05d4 {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "\u05d0\u05e0\u05d0 \u05e6\u05d9\u05d9\u05df \u05ea\u05e0\u05d0\u05d9 \u05d7\u05d5\u05e7\u05d9 \u05e2\u05d1\u05d5\u05e8 {0}.", //No I18n
	"crm.field.valid.check" : "\u05d0\u05e0\u05d0 \u05d4\u05d6\u05df {0} \u05d7\u05d5\u05e7\u05d9.", //No I18n
	"crm.alert.label.savepattern" : "\u05e9\u05de\u05d5\u05e8 \u05d0\u05ea \u05d4\u05ea\u05d1\u05e0\u05d9\u05ea \u05dc\u05e4\u05e0\u05d9 \u05e9\u05d9\u05e0\u05d5\u05d9 \u05d4\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05d5\u05e1\u05d9\u05e3 \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd.",//No I18n
	"is" : "\u05d4\u05d5\u05d0",//No I18n
	"isn\'t" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0",//No I18n
	"contains" : "\u05de\u05db\u05d9\u05dc",//No I18n
	"doesn\'t contain" : "\u05dc\u05d0 \u05de\u05db\u05d9\u05dc",//No I18n
	"starts with" : "\u05de\u05ea\u05d7\u05d9\u05dc \u05d1",//No I18n
	"ends with" : "\u05de\u05e1\u05ea\u05d9\u05d9\u05dd \u05d1",//No I18n
	"is empty" : "\u05d4\u05d5\u05d0 \u05e8\u05d9\u05e7",//No I18n
	"is not empty" : "\u05d4\u05d5\u05d0 \u05dc\u05d0 \u05e8\u05d9\u05e7",//No I18n
	"is before" : "\u05e0\u05de\u05e6\u05d0 \u05dc\u05e4\u05e0\u05d9",//No I18n
	"is after" : "\u05e0\u05de\u05e6\u05d0 \u05d0\u05d7\u05e8\u05d9",//No I18n
	"between" : "\u05d1\u05d9\u05df",//No I18n
	"not between" : "\u05dc\u05d0 \u05d1\u05d9\u05df",//No I18n
	"Today" : "\u05d4\u05d9\u05d5\u05dd",//No I18n
	"Tommorow" : "\u05de\u05d7\u05e8",//No I18n
	"Tommorow Onwards" : "\u05d4\u05d7\u05dc \u05de\u05de\u05d7\u05e8",//No I18n
	"Yesterday" : "\u05d0\u05ea\u05de\u05d5\u05dc",//No I18n
	"Till Yesterday" : "\u05e2\u05d3 \u05d0\u05ea\u05de\u05d5\u05dc",//No I18n
	"Last Month" : "\u05d7\u05d5\u05d3\u05e9 \u05e9\u05e2\u05d1\u05e8",//No I18n
	"Current Month" : "\u05d7\u05d5\u05d3\u05e9 \u05e0\u05d5\u05db\u05d7\u05d9", //No I18n
	"Next Month" : "\u05d4\u05d7\u05d5\u05d3\u05e9 \u05d4\u05d1\u05d0", //No I18n
	"Last Week" : "\u05e9\u05d1\u05d5\u05e2 \u05e9\u05e2\u05d1\u05e8", //No I18n
	"Current Week" : "\u05e9\u05d1\u05d5\u05e2 \u05e0\u05d5\u05db\u05d7\u05d9", //No I18n
	"Next Week" : "\u05e9\u05d1\u05d5\u05e2 \u05d4\u05d1\u05d0", //No I18n
	"Age in Days" : "\u05d2\u05d9\u05dc \u05d1\u05d9\u05de\u05d9\u05dd", //No I18n
	"Due in Days" : "\u05d9\u05de\u05d9\u05dd \u05dc\u05de\u05d5\u05e2\u05d3", //No I18n
	"Scheduled" : "\u05de\u05ea\u05d5\u05d6\u05de\u05df", //No I18n
	"Attended Dialled" : "\u05d7\u05d9\u05d5\u05d2 \u05de\u05d0\u05d5\u05d9\u05e9", //No I18n
	"Unattended Dialled" : "\u05d7\u05d9\u05d5\u05d2 \u05d1\u05dc\u05ea\u05d9 \u05de\u05d0\u05d5\u05d9\u05e9", //No I18n
	"Overdue" : "\u05d1\u05d0\u05d9\u05d7\u05d5\u05e8", //No I18n
	"Cancelled" : "\u05d1\u05d5\u05d8\u05dc", //No I18n
	"Received" : "\u05d4\u05ea\u05e7\u05d1\u05dc", //No I18n
	"Missed" : "\u05e9\u05d9\u05d7\u05d5\u05ea \u05e9\u05dc\u05d0 \u05e0\u05e2\u05e0\u05d5", //No I18n
	"crm.alert.character.not.allowed" : "\u05d0\u05d9\u05e0\u05d5 \u05de\u05d5\u05ea\u05e8 ?{0}", //No I18n
	"crm.condition.in.last" : "\u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd", //No I18n
	"crm.zinvoice.dueIn" : "\u05e6\u05e4\u05d5\u05d9(\u05d4) \u05ea\u05d5\u05da", //No I18n
	"on" : "\u05d1",//No I18n
	"before" : "\u05dc\u05e4\u05e0\u05d9",//No I18n
	"crm.label.general.small.after" : "\u05d0\u05d7\u05e8\u05d9",//No I18n
	"crm.thisweek" : "\u05d4\u05e9\u05d1\u05d5\u05e2",//No I18n
	"crm.label.this.month" : "\u05d4\u05d7\u05d5\u05d3\u05e9",//No I18n
	"crm.thisyear" : "\u05d4\u05e9\u05e0\u05d4",//No I18n
	"crm.source.user.and.system" : "\u05de\u05e9\u05ea\u05de\u05e9 \u05d5 System",//No I18n
	"crm.source.user.or.system" : "\u05de\u05e9\u05ea\u05de\u05e9 \u05d0\u05d5 System",//No I18n
	"crm.label.system2" : "\u05de\u05e2\u05e8\u05db\u05ea",//No I18n
	"crm.source.user.only" : "\u05e8\u05e7 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea \u05de\u05e9\u05ea\u05de\u05e9",//No I18n
	"crm.source.system.only" : "\u05e8\u05e7 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea  \u05d4 System",//No I18n
	"crm.condition.till.today" : "\u05e2\u05d3 \u05d4\u05d9\u05d5\u05dd",//No I18n
	"game.month.left" : "1 \u05d7\u05d5\u05d3\u05e9",//No I18n
	"game.months.left" : "{0} \u05d7\u05d5\u05d3\u05e9\u05d9\u05dd",//No I18n
	"crm.condition.last.30.days" : "\u05d1 30 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//No I18n
	"crm.condition.last.60.days" : "\u05d1 60 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//No I18n
	"crm.condition.last.90.days" : "\u05d1 90 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//No I18n
	"crm.label.filter.typehere" : "\u05d4\u05e7\u05dc\u05d3 \u05db\u05d0\u05df", //No I18N
	"crm.filter.is.not" : "\u05d0\u05d9\u05e0\u05d5", //No I18n
	"crm.condition.until.now" : "\u05e2\u05d3 \u05e2\u05db\u05e9\u05d9\u05d5",//No I18n
	"crm.filter.email.isblocked" : "\u05d7\u05e1\u05d5\u05dd",//No I18n
	"crm.filter.email.isnotblocked" : "\u05d0\u05d9\u05e0\u05d5 \u05d7\u05e1\u05d5\u05dd",//No I18n
	"crm.label.no.results.match" : "\u05d0\u05d9\u05df \u05ea\u05d5\u05e6\u05d0\u05d5\u05ea \u05ea\u05d5\u05d0\u05de\u05d5\u05ea",//No I18n
	"crm.label.select.user" : "\u05dc\u05d7\u05e5 \u05db\u05d3\u05d9 \u05dc\u05d1\u05d7\u05d5\u05e8 \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd.", //No I18n
	"current.logged.in.user": "\u05de\u05e9\u05ea\u05de\u05e9 \u05e9\u05d4\u05ea\u05d7\u05d1\u05e8", //NO I18n
	"current.logged.in.user.definition": "\u05d4\u05de\u05e9\u05ea\u05de\u05e9 \u05e9\u05de\u05e4\u05e2\u05d9\u05dc \u05d0\u05ea \u05ea\u05d9\u05e2\u05d5\u05d3 \u05e4\u05e2\u05d5\u05dc\u05d4.", //NO i18n
	"crm.security.group": "\u05e7\u05d1\u05d5\u05e6\u05d4", //NO I18n
	"crm.security.role": "\u05ea\u05e4\u05e7\u05d9\u05d3", //NO I18n
	"Date" : "\u05ea\u05d0\u05e8\u05d9\u05da",//No I18n
	"crm.field.valid.decimal.check2" : "\u05d4\u05e1\u05e4\u05e8\u05d5\u05ea \u05d0\u05d7\u05e8\u05d9 \u05d4\u05e0\u05e7\u05d5\u05d3\u05d4 \u05d4\u05e2\u05e9\u05e8\u05d5\u05e0\u05d9\u05ea \u05d1\u05e9\u05d3\u05d4 <i>{0}</i> \u05e6\u05e8\u05d9\u05db\u05d5\u05ea \u05dc\u05d4\u05d9\u05d5\u05ea \u05e7\u05d8\u05e0\u05d5\u05ea \u05d0\u05d5 \u05e9\u05d5\u05d5\u05d5\u05ea \u05dc-{1}",//No I18n
	"crm.field.empty.check" : "{0} \u05dc\u05d0 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7.",//No I18n
	"crm.label.add.note": "\u05d4\u05d5\u05e1\u05e3 \u05d4\u05e2\u05e8\u05d4", //NO I18n
	"crm.label.simply.by": "\u05e2\u05dc \u05d9\u05d3\u05d9", //NO I18n
	"crm.general.addnote": "\u05d4\u05d5\u05e1\u05e3 \u05d4\u05e2\u05e8\u05d4", //NO I18n
	"crm.general.addtitle": "\u05d4\u05d5\u05e1\u05e3 \u05db\u05d5\u05ea\u05e8\u05ea", //NO I18n
	"crm.label.attach.file": "\u05d4\u05e6\u05de\u05d3 \u05e7\u05d5\u05d1\u05e5", //NO I18N
	"crm.button.cancel": "\u05d1\u05d9\u05d8\u05d5\u05dc", //NO I18N
	"crm.button.save": "\u05e9\u05de\u05d5\u05e8", //NO I18N
	"crm.button.mass.delete": "\u05de\u05d7\u05e7", //NO I18N
	"crm.warning.delete.record": "\u05d4\u05d0\u05dd \u05d0\u05ea\u05d4 \u05d1\u05d8\u05d5\u05d7 \u05e9\u05d1\u05e8\u05e6\u05d5\u05e0\u05da \u05dc\u05de\u05d7\u05d5\u05e7 \u05d0\u05ea  \u05f4{0}\u05f4?", //NO I18N
	"crm.label.yes": "\u05db\u05df", //NO I18N
	"crm.note.view.previous": "\u05d4\u05e6\u05d2 \u05d4\u05e2\u05e8\u05d5\u05ea \u05e7\u05d5\u05d3\u05de\u05d5\u05ea", //NO I18N
  "of": "\u05e9\u05dc", //NO I18N
	"crm.label.notes": "\u05d4\u05e2\u05e8\u05d5\u05ea", //NO I18N
	"crm.note.recent.first": "\u05d0\u05d7\u05e8\u05d5\u05df \u05e8\u05d0\u05e9\u05d5\u05df", //NO I18N
	"crm.note.recent.last": "\u05d0\u05d7\u05e8\u05d5\u05df \u05d1\u05e1\u05d5\u05e3", //NO I18N
	"crm.territory.label.only": "\u05e8\u05e7 {0}", //no i18n
	"crm.select" : "\u05d1\u05d7\u05e8",//No I18n
	"crm.button.apply.filter" : "\u05d4\u05d7\u05dc\u05ea \u05de\u05e1\u05e0\u05df",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05d6\u05d9\u05df \u05d9\u05d5\u05ea\u05e8 \u05de-{0} \u05e2\u05e8\u05db\u05d9\u05dd \u05dc\u05e9\u05d3\u05d4 \u05d6\u05d4.",//No I18n
	"PM" : "\u05d0\u05d7\u05d4\u05f4\u05e6",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "\u05e2\u05e8\u05db\u05d9\u05dd \u05db\u05e4\u05d5\u05dc\u05d9\u05dd \u05d0\u05d9\u05e0\u05dd \u05de\u05d5\u05e8\u05e9\u05d9\u05dd.",//no i18n
	"crm.duplicate.value.available": "\u05e0\u05de\u05e6\u05d0 {0} \u05e7\u05d9\u05d9\u05dd \u05e2\u05dd \u05d0\u05d5\u05ea\u05d5 {1}.",//no i18n
	"crm.duplicate.value.available.multiple": "\u05e0\u05de\u05e6\u05d0 \u05d9\u05d5\u05ea\u05e8 \u05de{0} \u05d0\u05d7\u05d3 \u05e2\u05dd \u05d0\u05d5\u05ea\u05d5 {1}.",//no i18n
	"crm.custombutton.nobuttons.found": "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05dc\u05d7\u05e6\u05e0\u05d9\u05dd",//no i18n
	"crm.custombutton.create.newbutton": "\u05e6\u05d5\u05e8 \u05dc\u05d7\u05e6\u05df",//no i18n
	"crm.custombutton.manage.button": "\u05e0\u05d4\u05dc \u05dc\u05d7\u05e6\u05e0\u05d9\u05dd",//no i18n
	"crm.custombutton.name": "\u05e9\u05dd",//no i18n
	"crm.customize.custombutton.function.desc": "\u05ea\u05d9\u05d0\u05d5\u05e8",//no i18n
	"crm.custombutton.action": "\u05dc\u05d7\u05e6\u05df \u05e4\u05e2\u05d5\u05dc\u05d4",//no i18n
	"crm.custombutton.empty.field.values": "\u05d4\u05e2\u05e8\u05da \u05d4\u05e8\u05d9\u05e7 \u05e0\u05de\u05e6\u05d0 \u05e2\u05d1\u05d5\u05e8 \u05d4\u05e9\u05d3\u05d5\u05ea \u05d4\u05d1\u05d0\u05d9\u05dd:",//no i18n
	"crm.custombutton.proceed.action": "\u05d4\u05d0\u05dd \u05d0\u05ea\u05d4 \u05d1\u05d8\u05d5\u05d7 \u05e9\u05d0\u05ea\u05d4 \u05e2\u05d3\u05d9\u05d9\u05df \u05de\u05e2\u05d5\u05e0\u05d9\u05d9\u05df \u05dc\u05d4\u05de\u05e9\u05d9\u05da?",//no i18n
	"zb.common.Cancel": "\u05d1\u05d9\u05d8\u05d5\u05dc",//no i18n
	"crm.yes.proceed": "\u05db\u05df, \u05d4\u05de\u05e9\u05da",//no i18n
	"crm.label.module.merge": "\u05de\u05d6\u05d2 {0}",//no i18n
	"crm.view.record": "\u05d4\u05e6\u05d2 \u05d0\u05ea {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "\u05d1\u05d7\u05e8 \u05e7\u05d5\u05d1\u05e5",//no i18n
	"crm.label.subform.addrows": "\u05d4\u05d5\u05e1\u05e3 \u05e9\u05d5\u05e8\u05d4",//no i18n
	"crm.button.save&new": "\u05e9\u05de\u05d5\u05e8 \u05d5\u05d7\u05d3\u05e9",//no i18n
	//end-cx create form keys
	"Jan" : "\u05d9\u05e0\u05d5",//No I18n
	"Feb" : "\u05e4\u05d1\u05e8",//No I18n
	"Mar" : "\u05de\u05e8\u05e5",//No I18n
	"Apr" : "\u05d0\u05e4\u05e8",//No I18n
	"Jun" : "\u05d9\u05d5\u05e0\u05d9",//No I18n
	"Jul" : "\u05d9\u05d5\u05dc\u05d9",//No I18n
	"Aug" : "\u05d0\u05d5\u05d2",//No I18n
	"Sep" : "\u05e1\u05e4\u05d8\u05f3",//No I18n
	"Oct" : "\u05d0\u05d5\u05e7\u05f3",//No I18n
	"Nov" : "\u05e0\u05d5\u05d1\u05f3",//No I18n
	"Dec" : "\u05d3\u05e6\u05de",//No I18n
	"crm.mb.newversion.msg4" : "\u05d0\u05d5\u05e7\u05d9\u05d9, \u05d4\u05d1\u05e0\u05ea\u05d9!", //no i18n
	"crm.label.More" :"\u05e2\u05d5\u05d3", //no i18n

	"crm.label.unmapped.stages" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\u05d4\u05e6\u05d2 \u05e2\u05d5\u05d3",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u05d4\u05e6\u05d2 \u05e4\u05d7\u05d5\u05ea",//No I18n

	 	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"\u05d4\u05e1\u05ea\u05d1\u05e8\u05d5\u05ea",//no i18n
	"Planned":"\u05de\u05ea\u05d5\u05db\u05e0\u05df",//no i18n
	"Invited":"\u05de\u05d5\u05d6\u05de\u05df",//no i18n
	"Sent":"\u05e0\u05e9\u05dc\u05d7",//no i18n
	"Received":"\u05d4\u05ea\u05e7\u05d1\u05dc",//no i18n
	"Opened":"\u05e0\u05e4\u05ea\u05d7",//no i18n
	"Responded":"\u05d4\u05d2\u05d9\u05d1",//no i18n
	"Bounced":"\u05d7\u05d6\u05e8",//no i18n
	"Opted\ Out":"\u05d4\u05e2\u05d3\u05d9\u05e3 \u05dc\u05e6\u05d0\u05ea",//no i18n
	"crm.filter.label.with.open":"\u05e2\u05dd {0} \u05e4\u05ea\u05d5\u05d7",//no i18n
	"crm.filter.label.without.open":"\u05d1\u05dc\u05d9 \u05dc\u05e4\u05ea\u05d5\u05d7 \u05d0\u05ea {0}",//no i18n
	"crm.filter.label.without.any":"\u05dc\u05dc\u05d0 \u05db\u05dc {0}",//no i18n
	"crm.filter.label.with.module":"\u05e2\u05dd {0}",//no i18n
	"crm.filter.label.activity.due":"\u05e2\u05d3 {0}",//no i18n
	"crm.filter.label.activity.done":"{0} \u05d1\u05d5\u05e6\u05e2",//no i18n
	"Notes":"\u05d4\u05e2\u05e8\u05d5\u05ea",//no i18n
	"crm.filter.label.notes.added":"\u05d4\u05e2\u05e8\u05d5\u05ea \u05e9\u05d4\u05ea\u05d5\u05d5\u05e1\u05e4\u05d5",//no i18n
	"crm.label.filter.email.status":"\u05e1\u05d8\u05d8\u05d5\u05e1 \u05d3\u05d5\u05d0\u05dc",//no i18n
	"crm.filter.label.sent":"\u05e0\u05e9\u05dc\u05d7",//no i18n
	"crm.filter.label.not.sent":"\u05dc\u05d0 \u05e0\u05e9\u05dc\u05d7",//no i18n
	"crm.filter.label.opened":"\u05e0\u05e4\u05ea\u05d7",//no i18n
	"crm.filter.label.not.opened":"\u05dc\u05d0 \u05e0\u05e4\u05ea\u05d7",//no i18n
	"crm.filter.label.received":"\u05d4\u05ea\u05e7\u05d1\u05dc",//no i18n
	"crm.filter.label.not.received":"\u05dc\u05d0 \u05d4\u05ea\u05e7\u05d1\u05dc",//no i18n
	"crm.filter.label.bounced":"\u05d7\u05d6\u05e8",//no i18n
	"crm.filter.label.opened.not.replied":"\u05e0\u05e4\u05ea\u05d7\u05d4 \u05d0\u05da \u05dc\u05d0 \u05e0\u05e2\u05e0\u05ea\u05d4", //no i18n
	"crm.filter.label.any":"\u05db\u05dc \u05d0\u05d7\u05d3 \u05de\u05d4\u05e0\u05f4\u05dc",//no i18n
	"crm.zia.config.potential.amount":"\u05db\u05de\u05d5\u05ea {0}",//no i18n
	"Quote\ Stage":"\u05e9\u05dc\u05d1 \u05e9\u05dc {0}",//no i18n
	"crm.module.owner":"\u05d1\u05e2\u05dc\u05d9\u05dd {0}",//no i18n
	"Potential\ Closing\ Date":"\u05ea\u05d0\u05e8\u05d9\u05da \u05e1\u05d2\u05d9\u05e8\u05ea {0}",//no i18n
	"crm.lead.prediction.likely.convert":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05d4\u05de\u05d9\u05e8",//no i18n
	"crm.lead.prediction.convert.high":"\u05d2\u05d1\u05d5\u05d4",//no i18n
	"crm.lead.prediction.convert.medium":"\u05d1\u05d9\u05e0\u05d5\u05e0\u05d9",//no i18n
	"crm.lead.prediction.convert.low":"\u05e0\u05de\u05d5\u05da",//no i18n
	"crm.intelligence.prediction.likelywin":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05e0\u05e6\u05d7",//no i18n
	"crm.intelligence.prediction.likelylose":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05d4\u05e4\u05e1\u05d9\u05d3",//no i18n
	"crm.intelligence.prediction.halfchance":"\u05e1\u05d9\u05db\u05d5\u05d9 \u05e9\u05dc 50:50",//no i18n
	"crm.intelligence.prediction.score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e6\u05e4\u05d9",//no i18n
	"crm.lead.prediction.recent.score":"\u05d4\u05d3\u05d9\u05e8\u05d5\u05d2 \u05d4\u05d0\u05d7\u05e8\u05d5\u05df \u05e9\u05dc \u05d4\u05e6\u05e4\u05d9",//no i18n
	"crm.intelligence.prediction.lastconv":"3 \u05d4\u05e9\u05d9\u05d7\u05d5\u05ea \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d5\u05ea",//no i18n
	"crm.intelligence.prediction.recordsfocus":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05e2\u05dc\u05d9\u05d4\u05df \u05d9\u05e9 \u05dc\u05d4\u05ea\u05de\u05e7\u05d3",//no i18n
	"crm.intelligence.prediction.slowmoving":"\u05de\u05e2\u05d1\u05e8 \u05d0\u05d9\u05d8\u05d9",//no i18n
	"crm.intelligence.prediction.trend.down":"\u05d1\u05de\u05d2\u05de\u05ea \u05d9\u05e8\u05d9\u05d3\u05d4 \u05dc\u05d0\u05d7\u05e8\u05d5\u05e0\u05d4",//no i18n
	"crm.label.touched.records":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05e9\u05d4\u05e9\u05ea\u05e0\u05d5",//no i18n
	"crm.label.untouched.records":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05e9\u05dc\u05d0 \u05d4\u05e9\u05ea\u05e0\u05d5",//no i18n
	"crm.label.record.action":"\u05e4\u05e2\u05d5\u05dc \u05e8\u05e9\u05d5\u05de\u05d4",//no i18n
	"workflow.rule.view.label.Modified":"\u05d4\u05e9\u05ea\u05e0\u05d4",//no i18n
	"crm.label.not.modified":"\u05dc\u05d0 \u05d4\u05e9\u05ea\u05e0\u05d4",//no i18n
	"crm.label.related.records.action":"\u05e4\u05e2\u05d5\u05dc\u05ea \u05ea\u05d9\u05e2\u05d5\u05d3 \u05de\u05e7\u05d5\u05e9\u05e8\u05ea",//no i18n
	"Done":"\u05d1\u05d5\u05e6\u05e2",//no i18n
	"crm.label.not.done":"\u05dc\u05d0 \u05d1\u05d5\u05e6\u05e2",//no i18n
	"sentiment.model":"\u05e8\u05d2\u05e9 \u05d3\u05d5\u05d0\u05f4\u05dc",//no i18n
	"sentiment.criteria.count":"\u05e1\u05e4\u05d9\u05e8\u05d4",//no i18n
	"sentiment.criteria.percentage":"\u05d0\u05d7\u05d5\u05d6",//no i18n
	"sentiment.criteria.lastmail":"\u05e2\u05d1\u05d5\u05e8 \u05d4\u05d0\u05d9\u05de\u05d9\u05d9\u05dc \u05d4\u05d0\u05d7\u05e8\u05d5\u05df",//no i18n
	"Chats":"\u05e6\u05d0\u05d8\u05d9\u05dd",//no i18n
	"Attended":"\u05d4\u05e9\u05ea\u05ea\u05e3",//no i18n
	"crm.lead.prediction.popup.text":"\u05db\u05d0\u05e9\u05e8 \u05e1\u05d1\u05d9\u05e8 \u05e9 {0} \u05d9\u05e2\u05d1\u05d5\u05e8 \u05d4\u05de\u05e8\u05d4, \u05d4\u05e6\u05e4\u05d9 \u05e9\u05dc \u05e6\u05d1\u05d9\u05e8\u05ea \u05d4\u05e0\u05e7\u05d5\u05d3\u05d5\u05ea \u05d0\u05de\u05d5\u05e8 \u05dc\u05d4\u05d9\u05d5\u05ea \u05d1\u05d9\u05df {1}.",//no i18n
	"crm.lead.prediction.popup.final":"\u05e0\u05d0 \u05e9\u05e0\u05d4 \u05d0\u05ea \u05d4\u05de\u05e1\u05e0\u05df \u05d1\u05d4\u05ea\u05d0\u05dd \u05d5\u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1.",//no i18n
	"crm.custom.field.less.than.to1":"\u05d8\u05d5\u05d5\u05d7 \u05f4\u05de\u05d0\u05ea\u05f4 \u05dc\u05d0 \u05d9\u05d4\u05d9\u05d4 \u05d2\u05d3\u05d5\u05dc \u05d9\u05d5\u05ea\u05e8 \u05de\u05d8\u05d5\u05d5\u05d7 \u05f4\u05d0\u05dc\u05f4.",//no i18n
	"crm.custom.field.less.than.to":"\u05d4\u05d8\u05d5\u05d5\u05d7 \u05e9\u05dc <i>\u05de</i> \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05d2\u05d3\u05d5\u05dc \u05d9\u05d5\u05ea\u05e8 \u05de\u05d4\u05d8\u05d5\u05d5\u05d7 \u05e9\u05dc  <i>\u05dc</i>.", //no i18n
	"Last\ Activity\ Date":"\u05ea\u05d0\u05e8\u05d9\u05da \u05e4\u05e2\u05d9\u05dc\u05d5\u05ea \u05d0\u05d7\u05e8\u05d5\u05e0\u05d4",//no i18n
	"crm.label.vendor.name":"\u05e9\u05dd {0}",//no i18n
	"hours":"\u05e9\u05e2\u05d5\u05ea",//no i18n
	"days":"\u05d9\u05de\u05d9\u05dd",//no i18n
	"weeks":"\u05e9\u05d1\u05d5\u05e2\u05d5\u05ea",//no i18n
	"months":"\u05d7\u05d3\u05e9\u05d9\u05dd",//no i18n
	"years":"\u05e9\u05e0\u05d9\u05dd",//no i18n
	"crm.label.general.small.after":"\u05d0\u05d7\u05e8\u05d9",//no i18n
	"Last\ Week":"\u05e9\u05d1\u05d5\u05e2 \u05e9\u05e2\u05d1\u05e8",//no i18n
	"Last\ Month":"\u05d7\u05d5\u05d3\u05e9 \u05e7\u05d5\u05d3\u05dd",//no i18n
	"crm.module.name":"\u05e9\u05dd {0}",//no i18n
	"Campaign":"\u05e7\u05de\u05e4\u05d9\u05d9\u05df",//no i18n
	"Tasks":"\u05de\u05e9\u05d9\u05de\u05d5\u05ea",//no i18n
	"Calls":"\u05e9\u05d9\u05d7\u05d5\u05ea \u05d8\u05dc\u05e4\u05d5\u05df",//no i18n
	"Events":"\u05d0\u05d9\u05e8\u05d5\u05e2\u05d9\u05dd",//no i18n
	"sentiment.criteria.wrongcriteria":"\u05e2\u05e8\u05da \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05df \u05dc\u05d0 \u05d9\u05e2\u05dc\u05d4 \u05e2\u05dc \u200E{0}\u200E",//no i18n
	"crm.chosen.minimum.input.text":"\u05e0\u05d0 \u05d4\u05d6\u05df  {0}   \u05d0\u05d5 \u05d9\u05d5\u05ea\u05e8 \u05ea\u05d5\u05d5\u05d9\u05dd",//no i18n
	"crm.intelligence.prediction.trendup":"\u05e0\u05d5\u05d8\u05d4 \u05de\u05d8\u05d4",//no i18n
	"crm.intelligence.prediction.trenddown":"\u05d1\u05de\u05d2\u05de\u05ea \u05d9\u05e8\u05d9\u05d3\u05d4",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"\u05d4\u05d5\u05e9\u05dc\u05dd" ,//no i18n
	"crm.label.success":"\u05d4\u05e6\u05dc\u05d7\u05d4" ,//no i18n
	"crm.label.Failure":"\u05db\u05d9\u05e9\u05dc\u05d5\u05df" ,//no i18n
	"Both":"\u05e9\u05e0\u05d9\u05d4\u05dd" ,//no i18n
	"crm.condition.cannot.empty":"\u05ea\u05e0\u05d0\u05d9 \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7",//no i18n
	"crm.predictions.feature.label":"\u05ea\u05d7\u05d6\u05d9\u05ea",//no i18n
	"crm.condition.last.30.days":"\u05d1 30 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//no i18n
	"crm.condition.last.60.days":"\u05d1 60 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//no i18n
	"crm.condition.last.90.days":"\u05d1 90 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//no i18n
	"crm.sentiment.Positive":"\u05d7\u05d9\u05d5\u05d1\u05d9",//no i18n
	"crm.sentiment.Negative":"\u05e9\u05dc\u05d9\u05dc\u05d9",//no i18n
	"sentiment.positiveandnegative":"\u05d7\u05d9\u05d5\u05d1\u05d9 \u05d5\u05e9\u05dc\u05d9\u05dc\u05d9",//no i18n
	"sentiment.positiveornegative":"\u05d7\u05d9\u05d5\u05d1\u05d9 \u05d0\u05d5 \u05e9\u05dc\u05d9\u05dc\u05d9",//no i18n
	"sentiment.positiveonly":"\u05d7\u05d9\u05d5\u05d1\u05d9 \u05d1\u05dc\u05d1\u05d3",//no i18n
	"sentiment.negativeonly":"\u05e9\u05dc\u05d9\u05dc\u05d9 \u05d1\u05dc\u05d1\u05d3",//no i18n
	"crm.sentiment.Neutral":"\u05e0\u05d9\u05d9\u05d8\u05e8\u05dc\u05d9",//no i18n
	"crm.filters.select.campaign.type":"\u05d1\u05d7\u05e8 \u05e1\u05d5\u05d2  {0}",//no i18n
	"crm.filters.select.campaign.status":"\u05d1\u05d7\u05e8 \u05e1\u05d8\u05d8\u05d5\u05e1 {0}",//no i18n
	"campaign.Member" : "\u05d7\u05d1\u05e8",//no i18n
	"Service":"\u05e9\u05d9\u05e8\u05d5\u05ea",//no i18n
	"Activities":"\u05e4\u05e2\u05d9\u05dc\u05d5\u05d9\u05d5\u05ea",//no i18n
	"crm.livedesk.pot.nextdays":"{0} \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d1\u05d0\u05d9\u05dd",//no i18n
	"Today\ +\ Overdue":"\u05d4\u05d9\u05d5\u05dd + \u05d6\u05de\u05df \u05e4\u05d9\u05d2\u05d5\u05e8",//no i18n
	"crm.source.user.and.system":"\u05de\u05e9\u05ea\u05de\u05e9 \u05d5 System",//no i18n
	"crm.source.user.or.system":"\u05de\u05e9\u05ea\u05de\u05e9 \u05d0\u05d5 System",//no i18n
	"User":"\u05de\u05e9\u05ea\u05de\u05e9",//no i18n
	"crm.source.user.only":"\u05e8\u05e7 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea \u05de\u05e9\u05ea\u05de\u05e9",//no i18n
	"crm.source.system.only":"\u05e8\u05e7 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea  \u05d4 System",//no i18n
	"Scheduled":"\u05de\u05ea\u05d5\u05db\u05e0\u05df",//no i18n
	"Attended\ Dialled":"\u05d7\u05d9\u05d5\u05d2 \u05de\u05d0\u05d5\u05d9\u05e9",//no i18n
	"Unattended\ Dialled":"\u05d7\u05d9\u05d5\u05d2 \u05d1\u05dc\u05ea\u05d9 \u05de\u05d0\u05d5\u05d9\u05e9",//no i18n
	"Cancelled":"\u05d1\u05d5\u05d8\u05dc",//no i18n
	"crm.filter.email.isblocked":"\u05d7\u05e1\u05d5\u05dd",//no i18n
	"crm.filter.email.isnotblocked":"\u05d0\u05d9\u05e0\u05d5 \u05d7\u05e1\u05d5\u05dd",//no i18n
	"condition.till.now":"\u05e2\u05d3 \u05e2\u05db\u05e9\u05d9\u05d5",//no i18n
	"crm.recurring.no.months":"{0} \u05d7\u05d5\u05d3\u05e9\u05d9\u05dd",//no i18n
	"crm.lead.prediction.tooltip":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05d4\u05de\u05d9\u05e8 - \u05d8\u05d5\u05d5\u05d7 \u05d3\u05d9\u05e8\u05d5\u05d2",//no i18n
	"crm.website.activity":"\u05e4\u05e2\u05d9\u05dc\u05d5\u05ea \u05d0\u05ea\u05e8 \u05d0\u05d9\u05e0\u05d8\u05e8\u05e0\u05d8",//no i18n
	"crm.label.By":"\u05e2\u05dc \u05d9\u05d3\u05d9",//no i18n
	"crm.chosen.searching.text":"\u05de\u05d7\u05e4\u05e9...",//no i18n
	"crm.label.memberstatus.is":"\u05d5\u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u05d4\u05d7\u05d1\u05e8 \u05d4\u05d5\u05d0",//no i18n
	"crm.events.duration":"\u05de\u05e9\u05da",//no i18n
	"crm.title.clear.name":"\u05e0\u05e7\u05d4",//no i18n
	"crm.label.status.is":"\u05d5\u05d4\u05e1\u05d8\u05d8\u05d5\u05e1 \u05d4\u05d5\u05d0",//no i18n
	"zia.last3.help":"\u05e9\u05d9\u05d7\u05d5\u05ea \u05db\u05d5\u05dc\u05dc\u05d5\u05ea \u05e9\u05d9\u05d7\u05d5\u05ea \u05d8\u05dc\u05e4\u05d5\u05df, \u05de\u05e9\u05d9\u05de\u05d5\u05ea,  {0} , \u05d3\u05d5\u05d0\u05f4\u05dc \u05e9\u05d4\u05ea\u05e7\u05d1\u05dc, \u05d4\u05e2\u05e8\u05d5\u05ea, \u05d1\u05d9\u05e7\u05d5\u05e8\u05d9\u05dd, \u05d4\u05e2\u05e8\u05d5\u05ea \u05d7\u05d1\u05e8\u05ea\u05d9\u05d5\u05ea, \u05d1\u05e7\u05e9\u05d5\u05ea \u05ea\u05de\u05d9\u05db\u05d4 \u05de Desk.",//no i18n
	"crm.label.tag.related.to":"\u05e7\u05e9\u05d5\u05e8 \u05dc",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u05d9\u05d9\u05e6\u05d5\u05e8 {0} \u05d7\u05d3\u05e9",//No I18n
	"crm.krp.no.records.found" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 {0}",//No I18n
	"crm.module.new" : "{0} \u05d7\u05d3\u05e9",//No I18n
	"crm.label.view" : "\u05e6\u05e4\u05d4",//No I18n
	"crm.nsocial.customers" : "\u05dc\u05e7\u05d5\u05d7\u05d5\u05ea",//No I18n
	"crm.nsocial.open.potential" : "{0} \u05e4\u05ea\u05d5\u05d7\u05d5\u05ea",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u05d0\u05d7\u05e8\u05d9\u05dd",//No i18n
	"crm.field.length.check" : "\u05e2\u05e8\u05da \u05d4{0} \u05d7\u05d5\u05e8\u05d2 \u05de\u05d4\u05d0\u05d5\u05e8\u05da \u05d4\u05de\u05e8\u05d1\u05d9.", //No I18n
	"crm.lower.now":"\u05e2\u05db\u05e9\u05d9\u05d5",//no i18n
	"crm.time.min.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05d3\u05e7\u05d5\u05ea",//no i18n
	"crm.time.mins.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05d3\u05e7\u05d5\u05ea",//no i18n
	"crm.time.hr.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05e9\u05e2\u05d4",//no i18n
	"crm.time.hrs.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05e9\u05e2\u05d5\u05ea", //no i18n
	"AllUsers": "\u05db\u05dc \u05d4\u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd", //no i18n
	"crm.label.search":"\u05d7\u05d9\u05e4\u05d5\u05e9",//no i18n
	"crm.api.filterby":"\u05e1\u05e0\u05df \u05dc\u05e4\u05d9",//no i18n
	"crm.customview.nofields.found":"--\u05d0\u05d9\u05df \u05e9\u05d3\u05d5\u05ea \u05ea\u05d5\u05d0\u05de\u05d9\u05dd--",//no i18n
	"crm.setup.system.ziarecommendation":"\u05d4\u05de\u05dc\u05e6\u05d4",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05e2\u05e8\u05da.",//no i18n
	"crm.cal.custom":"\u05de\u05d5\u05ea\u05d0\u05dd \u05d0\u05d9\u05e9\u05d9\u05ea",//no i18n
	"crm.mb.field.common.empt":"\u05d4\u05e2\u05e8\u05da \u05dc\u05d0 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7.",//no i18n
	"crm.chosen.error.loading.text":"\u05d0\u05d5\u05e4\u05e1, \u05dc\u05d0 \u05d4\u05e6\u05dc\u05d7\u05e0\u05d5 \u05dc\u05d8\u05e2\u05d5\u05df \u05d0\u05ea \u05d4\u05ea\u05d5\u05e6\u05d0\u05d5\u05ea.",//no i18n
	"crm.mxnlookup.select" : "\u05d4\u05e7\u05e6\u05d4  {0}",//No I18n
	"crm.lookup.chooserecord":"\u05d1\u05d7\u05d9\u05e8\u05ea {0}",//no i18n
	"crm.record.selected":"{0} \u05e9\u05e0\u05d1\u05d7\u05e8",//no i18n
	"crm.module.empty.message" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 {0}.",//No I18n
	"crm.mxnlookup.selected" : "{0}  \u05e9\u05d4\u05d5\u05e7\u05e6\u05d5",//No I18n
	"crm.security.error" : "\u05d0\u05d9\u05df \u05dc\u05da \u05de\u05e1\u05e4\u05d9\u05e7 \u05d4\u05e8\u05e9\u05d0\u05d5\u05ea \u05dc\u05d1\u05d9\u05e6\u05d5\u05e2 \u05e4\u05e2\u05d5\u05dc\u05d4 \u05d6\u05d5. \u05e4\u05e0\u05d4 \u05d0\u05dc \u05de\u05e0\u05d4\u05dc \u05d4\u05de\u05e2\u05e8\u05db\u05ea \u05e9\u05dc\u05da.", //no i18n
	"crm.label.creator.noPermission" : "\u05d4\u05d4\u05e8\u05e9\u05d0\u05d4 \u05e0\u05d3\u05d7\u05ea\u05d4", //no i18n
	"crm.reviewprocess.smart.filter" : "\u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u05e8\u05e9\u05d5\u05de\u05ea \u05ea\u05d4\u05dc\u05d9\u05da \u05e1\u05e7\u05d9\u05e8\u05d4", //no i18n
	"crm.segmentation.segment.score" : "\u05e0\u05d9\u05e7\u05d5\u05d3 \u05de\u05d3\u05d5\u05e8" ,//No I18n
	"crm.segmentation.frequency" : "\u05ea\u05d3\u05d9\u05e8\u05d5\u05ea", //No I18n
	"crm.segmentation.recency" : "\u05de\u05d9\u05d3\u05ea \u05d4\u05e2\u05d3\u05db\u05e0\u05d9\u05d5\u05ea", //No I18n
	"crm.segmentation.monetary" : "\u05db\u05e1\u05e4\u05d9", //No I18n
	"crm.smartfilter.related.module.msg" : "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de\u05e9\u05dc\u05d5\u05e9\u05d4 \u05de\u05d5\u05d3\u05d5\u05dc\u05d9\u05dd \u05e7\u05e9\u05d5\u05e8\u05d9\u05dd." , //no i18n
	"crm.smartfilter.related.module.msg1"  : "(\u05dc\u05d3\u05d5\u05d2\u05f3 : \u05d3\u05d5\u05d0\u05dc, \u05e4\u05e2\u05d9\u05dc\u05d5\u05d9\u05d5\u05ea, \u05d4\u05e2\u05e8\u05d5\u05ea)", //no i18n
	"crm.smartfilter.related.module.msg2" : "\u05f4\u05de\u05e9\u05da\u05f4 \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7", //no i18n
	"crm.label.timeZone": "\u05d0\u05d6\u05d5\u05e8 \u05d6\u05de\u05df", //NO I18n
	"crm.label.insufficient.privileges": "\u05d4\u05e8\u05e9\u05d0\u05d5\u05ea \u05d1\u05dc\u05ea\u05d9 \u05de\u05e1\u05e4\u05d9\u05e7\u05d5\u05ea \u05dc\u05d1\u05d9\u05e6\u05d5\u05e2 \u05d4\u05e4\u05e2\u05d5\u05dc\u05d4. \u05e6\u05d5\u05e8 \u05e7\u05e9\u05e8 \u05e2\u05dd \u05d4 Admin \u05e9\u05dc\u05da.", //NO I18n
	"crm.createfield.calcinfo.new" : "\u05e9\u05d3\u05d4 \u05d6\u05d4 \u05e4\u05d5\u05e2\u05dc \u05db\u05de\u05d7\u05e9\u05d1\u05d5\u05df \u05e2\u05d1\u05d5\u05e8 \u05db\u05dc \u05d1\u05d9\u05d8\u05d5\u05d9 \u05e9\u05d9\u05d5\u05d6\u05df \u05d1\u05d5. </br> <b>\u05dc\u05d3\u05d5\u05d2\u05f3:  20+20</b> \u05d9\u05e0\u05d9\u05d1  <b>40</b> \u05d1\u05d0\u05d5\u05e4\u05df \u05d0\u05d5\u05d8\u05d5\u05de\u05d8\u05d9",//No i18n
	"crm.lable.read.only" : "\u05e9\u05d3\u05d4 \u05e7\u05e8\u05d9\u05d0\u05d4 \u05d1\u05dc\u05d1\u05d3",//No i18n
	"crm.column.sort.asc" : "\u05e1\u05d3\u05e8 \u05e2\u05d5\u05dc\u05d4",//No I18n
	"crm.column.sort.desc" : "\u05e1\u05d3\u05e8 \u05d9\u05d5\u05e8\u05d3",//No i18n
	"crm.column.unsort" : "\u05dc\u05dc\u05d0 \u05de\u05d9\u05d5\u05df",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u05e9\u05ea\u05e3 \u05e2\u05dd \u05dc\u05e7\u05d5\u05d7", //NO I18N
	"crm.label.edited":"\u05e0\u05e2\u05e8\u05da",//no i18n
	"crm.label.edited.on":"\u05e0\u05e2\u05e8\u05da \u05d1-",//no i18n
	"crm.message.limit.exceed": "\u05e8\u05e7 {0} \u05ea\u05d5\u05d5\u05d9\u05dd \u05de\u05d5\u05ea\u05e8\u05d9\u05dd \u05e2\u05d1\u05d5\u05e8 {1}", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u05de\u05e9\u05d5\u05ea\u05e3 \u05e2\u05dd \u05dc\u05e7\u05d5\u05d7", //NO I18N
	"crm.button.ok" : "\u05d0\u05d9\u05e9\u05d5\u05e8", //NO I18N
	"crm.role.already.selected" : "\u05ea\u05e4\u05e7\u05d9\u05d3 \u05d6\u05d4 \u05db\u05d1\u05e8 \u05e0\u05d1\u05d7\u05e8", //no i18n
	"crm.user.deleted": "\u05d4\u05de\u05e9\u05ea\u05de\u05e9 \u05e0\u05de\u05d7\u05e7",  //NO I18N
	"crm.account.closed": "\u05d7\u05e9\u05d1\u05d5\u05df \u05d6\u05d4 \u05e0\u05e1\u05d2\u05e8",  //NO I18N
	"crm.start.chat": "\u05d4\u05ea\u05d7\u05dc \u05e6'\u05d0\u05d8",  //NO I18N
	"crm.start.call": "\u05d4\u05ea\u05d7\u05dc \u05e9\u05d9\u05d7\u05d4",  //NO I18N
	"crm.recipient.invalid.email" : "\u05e0\u05de\u05e6\u05d0\u05d5 \u05d3\u05d5\u05d0'\u05dc\u05d9\u05dd \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9\u05d9\u05dd.", //NO I18N
	"crm.recipient.add.recipient" : "\u05d4\u05d5\u05e1\u05e3 \u05e0\u05de\u05e2\u05df \u05e0\u05d5\u05e1\u05e3", //NO I18N
	"crm.start.video.call": "\u05d4\u05ea\u05d7\u05dc \u05e9\u05d9\u05d7\u05ea \u05d5\u05d9\u05d3\u05d0\u05d5",  //NO I18N //ignorei18n_start

	"Score":"\u05d3\u05d9\u05e8\u05d5\u05d2",
	"Positive Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05d7\u05d9\u05d5\u05d1\u05d9",
	"Positive Touch Point Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05d7\u05d9\u05d5\u05d1\u05d9 \u05e9\u05dc \u05e0\u05e7\u05d5\u05d3\u05ea \u05d4\u05de\u05d2\u05e2",
	"Negative Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e9\u05dc\u05d9\u05dc\u05d9",
	"Negative Touch Point Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e9\u05dc\u05d9\u05dc\u05d9 \u05e9\u05dc \u05e0\u05e7\u05d5\u05d3\u05ea \u05d4\u05de\u05d2\u05e2",
	"Touch Point Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e9\u05dc \u05e0\u05e7\u05d5\u05d3\u05ea \u05d4\u05de\u05d2\u05e2",
	"crm.label.scoring.rules":"\u05db\u05dc\u05dc\u05d9 \u05d3\u05d9\u05e8\u05d5\u05d2",
	"crm.label.type.minutes": "\u05d4\u05e7\u05dc\u05d3 \u05db\u05d0\u05df \u05d1\u05d3\u05e7\u05d5\u05ea", //NO I18N

	"is\ OPEN":"\u05e4\u05ea\u05d5\u05d7\u05d4",//no i18n
	"is\ WON":"\u05d6\u05db\u05d5\u05d4 \u05d1\u05d4",//no i18n
	"is\ LOST":"\u05d4\u05d5\u05e4\u05e1\u05d3\u05d4",//no i18n
	"crm.potential.all.open":"\u05db\u05dc \u05d4\u05e9\u05dc\u05d1\u05d9\u05dd \u05d4\u05e4\u05ea\u05d5\u05d7\u05d9\u05dd",//no i18n
	"crm.potential.all.won":"\u05db\u05dc \u05d4\u05e9\u05dc\u05d1\u05d9\u05dd \u05e9\u05e0\u05d5\u05e6\u05d7\u05d5 \u05e9\u05e0\u05e1\u05d2\u05e8\u05d5",//no i18n
	"crm.potential.all.lost":"\u05db\u05dc \u05d4\u05e9\u05dc\u05d1\u05d9\u05dd \u05e9\u05d4\u05d5\u05e4\u05e1\u05d3\u05d5 \u05e9\u05e0\u05e1\u05d2\u05e8\u05d5",//no i18n

	"crm.campaign.member.status" : "\u05e1\u05d8\u05d8\u05d5\u05e1 \u05d7\u05d1\u05e8",//no i18n
	"crm.dashboard.select.type" : "\u05d1\u05d7\u05e8 {0}",//no i18n
	"crm.campaign.service.status":"\u05e1\u05d8\u05d8\u05d5\u05e1 \u05d4\u05e9\u05d9\u05e8\u05d5\u05ea",//no i18n

	"crm.label.addColumn":"\u05d4\u05d5\u05e1\u05e3 \u05e2\u05de\u05d5\u05d3\u05d4",//no i18n
	"crm.button.clear.filter":"\u05e1\u05d2\u05d5\u05e8 \u05de\u05e1\u05e0\u05df",//no i18n
	"crm.button.show.filter":"\u05d4\u05e6\u05d2 \u05de\u05e1\u05e0\u05df",//no i18n
	"crm.las.error.user.maxlimit":"\u05d4\u05de\u05e1\u05e4\u05e8 \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd \u05e9\u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d4\u05d5\u05d0 20.",//no i18n
	"crm.las.error.picklist.maxlimit":"\u05d4\u05de\u05e1\u05e4\u05e8 \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc \u05d0\u05e4\u05e9\u05e8\u05d5\u05d9\u05d5\u05ea \u05e9\u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d4\u05d5\u05d0 20.",//no i18n

	"crm.fileuploader.message.responseerror": "\u05d4\u05d4\u05e2\u05dc\u05d0\u05d4 \u05e0\u05db\u05e9\u05dc\u05d4", //NO I18N
	"crm.storage.create.error":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d9\u05e6\u05d5\u05e8 \u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05d7\u05d3\u05e9\u05d5\u05ea \u05de\u05e9\u05d5\u05dd \u05e9\u05d4\u05d2\u05e2\u05ea \u05d0\u05dc \u05d2\u05d1\u05d5\u05dc \u05d0\u05d7\u05e1\u05d5\u05df \u05d4\u05e0\u05ea\u05d5\u05e0\u05d9\u05dd \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc\u05da.",//no i18n
	"crm.storage.create.error.client":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d9\u05e6\u05d5\u05e8 \u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05d7\u05d3\u05e9\u05d5\u05ea \u05de\u05e9\u05d5\u05dd \u05e9\u05d4\u05de\u05e0\u05d4\u05dc \u05e9\u05dc\u05da \u05d4\u05d2\u05d9\u05e2 \u05dc\u05d2\u05d1\u05d5\u05dc \u05d4\u05d0\u05d7\u05e1\u05d5\u05df \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc\u05d5. \u05e6\u05d5\u05e8 \u05e7\u05e9\u05e8 \u05e2\u05dd {0} \u05db\u05d3\u05d9 \u05dc\u05e4\u05ea\u05d5\u05e8 \u05d1\u05e2\u05d9\u05d4 \u05d6\u05d5.",//no i18n
	"crm.storage.avail.info":"({0} \u05e0\u05d5\u05ea\u05e8 \u05de\u05ea\u05d5\u05da {1})",//no i18n
	"crm.storage.error.key.manage":"\u05e0\u05d4\u05dc \u05d0\u05ea \u05db\u05dc \u05d0\u05d7\u05e1\u05d5\u05df \u05d4\u05e0\u05ea\u05d5\u05e0\u05d9\u05dd \u05e9\u05dc\u05da",//no i18n
	"Records":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u05e0\u05de\u05e2\u05e0\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u05d4\u05e9\u05ea\u05de\u05e9 \u05d1\u05e4\u05e1\u05d9\u05e7\u05d9\u05dd \u05dc\u05d4\u05e4\u05e8\u05d3\u05d4 \u05d1\u05d9\u05df \u05db\u05ea\u05d5\u05d1\u05d5\u05ea \u05d3\u05d5\u05d0\u05dc \u05e0\u05d5\u05e1\u05e4\u05d5\u05ea.", //NO I18N
	"crm.related.contact.account" : "{0} \u05de\u05e7\u05d5\u05e9\u05e8 \u05dc {1}",//No I18n
	"crm.allcontact.show" : "\u05db\u05dc \u05d4{0}",//No I18n
	"crm.button.mass.show" : "\u05d4\u05e6\u05d2",//No I18n
	"crm.msg.custom.view.not.replied" : "\u05d4\u05d5\u05d3\u05e2\u05d5\u05ea \u05e9\u05dc\u05d0 \u05e7\u05d9\u05d1\u05dc\u05d5 \u05de\u05e2\u05e0\u05d4", //NO I18N
	"crm.msg.custom.view.replied" : "\u05d4\u05d5\u05d3\u05e2\u05d5\u05ea \u05e9\u05e7\u05d9\u05d1\u05dc\u05d5 \u05de\u05e2\u05e0\u05d4",//NO I18N
	"crm.workflow.select.recipients" : "\u05e0\u05de\u05e2\u05e0\u05d9\u05dd", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u05d0\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05dc\u05e4\u05d7\u05d5\u05ea \u05e4\u05e8\u05d5\u05e4\u05d9\u05dc \u05d0\u05d7\u05d3.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u05de\u05e6\u05d8\u05e2\u05e8\u05d9\u05dd, \u05d0\u05da \u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05e1\u05d9\u05e8 \u05e4\u05e8\u05d5\u05e4\u05d9\u05dc \u05d3\u05d9\u05e4\u05d5\u05dc\u05d8\u05d9\u05d1\u05d9.",//NO I18N
	"crm.inv.label.add.emails" : "\u05d4\u05d5\u05e1\u05e3 \u05db\u05ea\u05d5\u05d1\u05d5\u05ea \u05d3\u05d5\u05d0\u05dc", //NO I18N
	"crm.prediction.analytics.filter.year":"\u05e9\u05e0\u05d4 \u05e9\u05e2\u05d1\u05e8\u05d4",//no i18n
	"Previous\ FY":"FY \u05e7\u05d5\u05d3\u05dd",//no i18n
	"Current\ FY":"FY \u05e0\u05d5\u05db\u05d7\u05d9",//no i18n
	"Next\ FY":"FY \u05d4\u05d1\u05d0",//no i18n
	"Current\ FQ":"FQ \u05e0\u05d5\u05db\u05d7\u05d9",//no i18n
	"Next\ FQ":"FQ \u05d4\u05d1\u05d0",//no i18n
	"Previous\ FQ":"FQ \u05e7\u05d5\u05d3\u05dd",//no i18n
	"crm.picklist.sample.text":"\u05d8\u05e7\u05e1\u05d8 \u05dc\u05d3\u05d5\u05d2\u05de\u05d4",//no i18n
	"crm.more.colors":"\u05e6\u05d1\u05e2\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd",//no i18n
	"crm.button.back.alone":"\u05d7\u05d6\u05e8\u05d4",//no i18n
	"crm.field.label.email":"\u05d3\u05d5\u05d0\u05f4\u05dc",//no i18n
"crm.zia.nba.feature.label":"\u05d4\u05d7\u05d5\u05d5\u05d9\u05d4 \u05d4\u05d1\u05d0\u05d4 \u05d4\u05d8\u05d5\u05d1\u05d4 \u05d1\u05d9\u05d5\u05ea\u05e8",//no i18n
"Meeting":"\u05e4\u05d2\u05d9\u05e9\u05d4",//no i18n
"Tomorrow":"\u05de\u05d7\u05e8",//no i18n
"crm.gdpr.notavailable.field":"\u05dc\u05d0 \u05d6\u05de\u05d9\u05df",//no i18n
	"crm.setup.system.ziasimilarity":"\u05d4\u05de\u05dc\u05e6\u05ea \u05d3\u05de\u05d9\u05d5\u05df",//no i18n
	"crm.gdpr.notavailable.field":"\u05dc\u05d0 \u05d6\u05de\u05d9\u05df",//no i18n
	"crm.label.simply.in":"\u05d1\u05ea\u05d5\u05da",//no i18n
	"crm.filter.label.all.products":"\u05db\u05dc \u05d4{0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "\u05e4\u05dc\u05d7\u05d9\u05dd", // NO I18N
	"abm.segment.names": "\u05e9\u05de\u05d5\u05ea \u05e4\u05dc\u05d7\u05d9\u05dd", // NO I18N
	"abm.abm.segment.name": "\u05e9\u05dd \u05e4\u05dc\u05d7 ABM", // NO I18N
	"abm.segmentation.techniques": "\u05d8\u05db\u05e0\u05d9\u05d5\u05ea \u05e4\u05d9\u05dc\u05d5\u05d7 ABM", // NO I18N
	"crm.column.list.max.group.limit" : "" ,//no i18n
	"abm.filters": "\u05de\u05e1\u05e0\u05e0\u05d9 ABM", // NO I18N
	"abm.filters.info": "\u05de\u05e1\u05e0\u05e0\u05d9\u05dd \u05d0\u05dc\u05d4 \u05d9\u05e6\u05d9\u05d2\u05d5 \u05de\u05d9\u05d3\u05e2 \u05d4\u05de\u05d1\u05d5\u05e1\u05e1 \u05e2\u05dc \u05d4-{0} \u05d4\u05de\u05e9\u05d5\u05d9\u05da \u05d0\u05dc \u05d4-{1}.", // NO I18N
	"abm.rfm.label": "\u05ea\u05d5\u05d5\u05d9\u05ea RFM", // NO I18N
	"abm.firmographics.label": "\u05ea\u05d5\u05d5\u05d9\u05ea \u05de\u05d9\u05d3\u05e2 \u05d2\u05e8\u05e4\u05d9" ,// NO I18N
	"abm.recommendation.label": "\u05ea\u05d5\u05d5\u05d9\u05ea \u05d4\u05de\u05dc\u05e6\u05d4", // NO I18N
	"abm.voc.label": "\u05ea\u05d5\u05d5\u05d9\u05ea VOC", // NO I18N
	"abm.engagement.label": "\u05ea\u05d5\u05d5\u05d9\u05ea \u05de\u05e2\u05d5\u05e8\u05d1\u05d5\u05ea", // NO I18N
	"abm.overall.score": "\u05d4\u05e6\u05d9\u05d5\u05df \u05d4\u05db\u05d5\u05dc\u05dc", // NO I18N
	"abm.mood.score": "\u05e6\u05d9\u05d5\u05df \u05de\u05e6\u05d1 \u05d4\u05e8\u05d5\u05d7", // NO I18N
	"abm.value.score": "\u05e6\u05d9\u05d5\u05df \u05e2\u05e8\u05da", // NO I18N
	"abm.engagement.score": "\u05e6\u05d9\u05d5\u05df \u05de\u05e2\u05d5\u05e8\u05d1\u05d5\u05ea", // NO I18N
	"abm.rfm.score": "\u05e6\u05d9\u05d5\u05df RFM", // NO I18N
	"crm.customview.pin.column":"\u05e0\u05e2\u05e5 \u05e2\u05de\u05d5\u05d3\u05d4",//no i18n
"crm.customview.unpin.column":"\u05d1\u05d8\u05dc \u05e0\u05e2\u05d9\u05e6\u05ea \u05e2\u05de\u05d5\u05d3\u05d4",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} צריך להיות גדול או שווה ל{1}.",
	"crux.users.selected.plural" : "נבחרו {0} משתמשים.",
	"crux.user.selected.singular" :"נבחר משתמש {0}.",
	"crux.criteria.empty.secondaryfield.module" : "לא נמצא שדה {0} תואם במודול {1}",
	"crux.criteria.empty.secondaryfield" : "אין שדה {0} אחר הזמין להשוואה, אנא הזן ערך להשוואה.",
	"crux.logged.in.role.definition" : "תפקיד המשתמש שמפעיל את פעולות הרישום",
	"zia.similarity.smartfilter.score":"\u05e6\u05d9\u05d5\u05df \u05d3\u05d5\u05de\u05d4",//no i18n
	"zia.similarity.smartfilter.records":"{0} \u05d3\u05d5\u05de\u05d4 \u05dc-",//no i18n
	"zia.similarity.smartfilter.records.search":"\u05d4\u05e6\u05d2 {0} \u05d3\u05d5\u05de\u05d9\u05dd \u05dc-",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u05dc\u05d0 \u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de\u05d0\u05e9\u05e8 {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} צריך להיות גדול או שווה ל{1}.",
	"crux.users.selected.plural" : "נבחרו {0} משתמשים.",
	"crux.user.selected.singular" :"נבחר משתמש {0}.",
	"crux.criteria.empty.secondaryfield.module" : "לא נמצא שדה {0} תואם במודול {1}",
	"crux.criteria.empty.secondaryfield" : "אין שדה {0} אחר הזמין להשוואה, אנא הזן ערך להשוואה.",
	"crux.logged.in.role.definition" : "תפקיד המשתמש שמפעיל את פעולות הרישום",
	"crux.max.limit.unselect" : "אינך יכול לבטל את הבחירה של יותר מאשר{0} {1}.", //NO I18N
	"crux.existing.tag" : "\u05f4{0}\u05f4 כבר נבחר", //NO I18N
	"crm.label.true" : "\u05e0\u05db\u05d5\u05df",//NO I18N
	"crm.label.false" : "\u05dc\u05d0 \u05e0\u05db\u05d5\u05df",//NO I18N
	"crm.record.locked" : "\u05e0\u05e2\u05d5\u05dc\u05d4",//NO I18N
	"crm.filter.number.range":"\u05e2\u05e8\u05db\u05d9\u05dd \u05de {0}-{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"\u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea \u05d6\u05d5 \u05e2\u05d1\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de 10 \u05e2\u05e8\u05db\u05d9\u05dd \u05e9\u05e0\u05d1\u05d7\u05e8\u05d5.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"\u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d1\u05d9\u05d5\u05ea\u05e8 \u05de 5  {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u05dc\u05d0 \u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de\u05d0\u05e9\u05e8 {0} {1}",//no i18n
	"crm.best.time.column.label":"\u05d6\u05de\u05df \u05de\u05d9\u05d8\u05d1\u05d9 \u05dc\u05d9\u05e6\u05d9\u05e8\u05ea \u05e7\u05e9\u05e8",//no i18n
	"crm.competitoralert.mentioned.in.email":"\u05d4\u05d5\u05d6\u05db\u05e8 \u05d1<b>\u05d3\u05d5\u05d0\u05f4\u05dc</b>",//no i18n
	"crm.competitoralert.with.sentiment":"\u05e2\u05dd \u05e1\u05e0\u05d8\u05d9\u05de\u05e0\u05d8",//no i18n
	"crm.competitoralert.name.error":"\u05d0\u05e0\u05d0 \u05e1\u05e4\u05e7 \u05e2\u05e8\u05da \u05d7\u05d5\u05e7\u05d9 \u05e2\u05d1\u05d5\u05e8 \u05e9\u05dd \u05d4\u05de\u05ea\u05d7\u05e8\u05d4.",//no i18n
	"crm.competitoralert.date.error":"\u05d0\u05e0\u05d0 \u05e1\u05e4\u05e7 \u05ea\u05d0\u05e8\u05d9\u05da \u05d7\u05d5\u05e7\u05d9 \u05e2\u05d1\u05d5\u05e8 \u05d4\u05de\u05ea\u05d7\u05e8\u05d4 \u05d4\u05de\u05d5\u05d6\u05db\u05e8 \u05d1\u05d3\u05d5\u05d0\u05f4\u05dc.",//no i18n
	"crm.competitoralert.sentiment.error":"\u05d0\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05d0\u05e4\u05e9\u05e8\u05d5\u05ea \u05d7\u05d5\u05e7\u05d9\u05ea \u05e2\u05d1\u05d5\u05e8 \u05e1\u05e0\u05d8\u05d9\u05de\u05e0\u05d8 \u05de\u05ea\u05d7\u05e8\u05d4.",//no i18n
	"crm.competitor.name":"\u05de\u05ea\u05d7\u05e8\u05d4",
"crm.lookup.advance.error.msg" : "\u05de\u05e9\u05d4\u05d5 \u05d4\u05e9\u05ea\u05d1\u05e9 \u05d1\u05e2\u05ea \u05d4\u05d7\u05dc\u05ea \u05d4\u05de\u05e1\u05e0\u05df. \u05e8\u05e2\u05e0\u05df \u05d0\u05ea \u05d4\u05d3\u05e3 \u05d5\u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1.",
	"crm.label.add.tags":"\u05d4\u05d5\u05e1\u05e3 \u05ea\u05d2\u05d9\u05d5\u05ea",//no i18n
	"crm.confirm.deassociate":"\u05d1\u05d8\u05dc \u05d4\u05e7\u05e6\u05d0\u05d4",//no i18n
	"Contact\ Name" : "\u05e9\u05dd {0}",
	"Lead\ Name" : "\u05e9\u05dd {0}",
	"-None-" : "\u05dc\u05dc\u05d0",
	"January" : "\u05d9\u05e0\u05d5\u05d0\u05e8",
	"February" : "\u05e4\u05d1\u05e8\u05d5\u05d0\u05e8",
	"March" : "\u05de\u05e8\u05e5",
	"April" : "\u05d0\u05e4\u05e8\u05d9\u05dc",
	"May" : "\u05de\u05d0\u05d9",
	"June" : "\u05d9\u05d5\u05e0\u05d9",
	"July" : "\u05d9\u05d5\u05dc\u05d9",
	"August" : "\u05d0\u05d5\u05d2\u05d5\u05e1\u05d8",
	"September" : "\u05e1\u05e4\u05d8\u05de\u05d1\u05e8",
	"October" : "\u05d0\u05d5\u05e7\u05d8\u05d5\u05d1\u05e8",
	"November" : "\u05e0\u05d5\u05d1\u05de\u05d1\u05e8",
	"December" : "\u05d3\u05e6\u05de\u05d1\u05e8",
	// Image Upload Open
	"crm.label.browse.files":"\u05e1\u05d9\u05d9\u05e8 \u05d1\u05e7\u05d1\u05e6\u05d9\u05dd",//no i18n
	"crm.label.lowercase.or":"\u05d0\u05d5",//no i18n
	"crm.image.header":"\u05ea\u05de\u05d5\u05e0\u05d4",//no i18n
	"crm.image.n.n":"\u05d9\u05d7\u05e1 N:N",//no i18n
	"crm.image.2.2":"\u05d9\u05d7\u05e1 2:2",//no i18n
	"crm.image.4.3":"\u05d9\u05d7\u05e1 4:3",//no i18n
	"crm.image.16.9":"\u05d9\u05d7\u05e1 16:9",//no i18n
	"crm.image.height.width.swap":"\u05d4\u05d7\u05dc\u05e3 \u05d2\u05d5\u05d1\u05d4 \u05d5\u05e8\u05d5\u05d7\u05d1",//no i18n
	"crm.image.rotate.image":"\u05e1\u05d5\u05d1\u05d1",//no i18n
	"crm.label.lowercase.of":"\u05e9\u05dc",//no i18n
	"crm.image.empty.name.placeholder" : "\u05d4\u05d6\u05df \u05e9\u05dd",
	"crm.image.crop.and.rotate" : "\u05d7\u05ea\u05d5\u05da \u05d5\u05e1\u05d5\u05d1\u05d1",
	"crm.image.description" : "\u05d4\u05d5\u05e1\u05e3 \u05ea\u05d9\u05d0\u05d5\u05e8...",
	"crm.image.actual.size" : "\u05d2\u05d5\u05d3\u05dc \u05de\u05de\u05e9\u05d9",
	"crm.image.reset" : "\u05d0\u05d9\u05e4\u05d5\u05e1",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\u05d4\u05d5\u05e8\u05d3",//no i18n
	"crm.label.edit":"\u05e2\u05e8\u05d5\u05da",//no i18n
	"crm.label.close":"\u05e1\u05d2\u05d5\u05e8",//no i18n
	"crm.label.any":"\u05db\u05dc\u05e9\u05d4\u05d5",
	"crm.image.description":"\u05d4\u05d5\u05e1\u05e3 \u05ea\u05d9\u05d0\u05d5\u05e8...",//no i18n
	"crm.image.zoom.in":"\u05d4\u05ea\u05e7\u05e8\u05d1",//no i18n
	"crm.image.zoom.out":"\u05d4\u05ea\u05e8\u05d7\u05e7",//no i18n
	"crm.label.lowercase.of":"\u05e9\u05dc",//no i18n
	"crm.image.desc.maxsize":"\u05d4\u05ea\u05d9\u05d0\u05d5\u05e8 \u05dc\u05d0 \u05d9\u05e2\u05dc\u05d4 \u05e2\u05dc 255 \u05ea\u05d5\u05d5\u05d9\u05dd.",//no i18n
	"crm.image.name.maxsize":"\u05d4\u05e9\u05dd \u05dc\u05d0 \u05d9\u05e2\u05dc\u05d4 \u05e2\u05dc 100 \u05ea\u05d5\u05d5\u05d9\u05dd.",//no i18n	
	"crm.fileuploader.message.totalfilesizeexceeded":"\u05d4\u05d2\u05d5\u05d3\u05dc \u05d4\u05db\u05d5\u05dc\u05dc \u05d7\u05d5\u05e8\u05d2 \u05de\u05d4\u05de\u05db\u05e1\u05d4 \u05d4\u05de\u05d5\u05ea\u05e8\u05ea \u05e9\u05dc {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"\u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea\u05da \u05dc\u05d4\u05e2\u05dc\u05d5\u05ea \u05e2\u05d3 {0} \u05e7\u05d1\u05e6\u05d9\u05dd \u05d1\u05dc\u05d1\u05d3.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"\u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea\u05da \u05dc\u05e6\u05e8\u05e3 \u05e2\u05d3 {0} \u05e7\u05d1\u05e6\u05d9\u05dd \u05d1\u05dc\u05d1\u05d3.",//no i18n
	"crm.ImageuploadField.size.limit":"\u05d4\u05d2\u05d5\u05d3\u05dc \u05d4\u05db\u05d5\u05dc\u05dc \u05e9\u05dc \u05d4\u05ea\u05de\u05d5\u05e0\u05d4(\u05d5\u05ea) \u05d7\u05d5\u05e8\u05d2 \u05de\u05d4\u05d2\u05d1\u05d5\u05dc \u05d4\u05de\u05d5\u05e8\u05e9\u05d4 \u05e9\u05dc  {0}  MB.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"\u05e0\u05d9\u05ea\u05df \u05dc\u05d1\u05d7\u05d5\u05e8 \u05ea\u05de\u05d5\u05e0\u05d4 1 \u05d1\u05dc\u05d1\u05d3.",//no i18n
	"crm.image.crop.error":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d7\u05ea\u05d5\u05da \u05d0\u05ea \u05d4\u05ea\u05de\u05d5\u05e0\u05d4, \u05e0\u05d0 \u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1!",//no i18n
	"crm.attach.here":"\u05db\u05d0\u05df.",//no i18n
	"crm.image.unsupported.corrupted.single":"\u05d4\u05ea\u05de\u05d5\u05e0\u05d4 \u05e9\u05d0\u05ea\u05d4 \u05de\u05e0\u05e1\u05d4 \u05dc\u05d4\u05e2\u05dc\u05d5\u05ea, \u05e4\u05d2\u05d5\u05de\u05d4.",//no i18n
	"Browse":"\u05e1\u05d9\u05d9\u05e8",//no i18n
	"crm.imageupload.failure":"\u05d9\u05e9 \u05db\u05de\u05d4 \u05ea\u05de\u05d5\u05e0\u05d5\u05ea \u05e9\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05d4\u05d9\u05d4 \u05dc\u05d4\u05e2\u05dc\u05d5\u05ea. \u05d0\u05e0\u05d0 \u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1 \u05de\u05d0\u05d5\u05d7\u05e8 \u05d9\u05d5\u05ea\u05e8.",//no i18n
	"zc.editor.width":"\u05e8\u05d5\u05d7\u05d1",//no i18n
	"ze.editor.height":"\u05d2\u05d5\u05d1\u05d4",//no i18n
	"crm.label.delete":"\u05de\u05d7\u05e7",//no i18n
	"crm.image.error.gif":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d7\u05ea\u05d5\u05da \u05ea\u05de\u05d5\u05e0\u05ea GIF.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} \u05e7\u05d1\u05e6\u05d9\u05dd \u05d0\u05d7\u05e8\u05d9\u05dd",//no i18n
	"crm.fileuploader.message.invalidfileType1":"\u05d4\u05e7\u05d5\u05d1\u05e5 {0} \u05d0\u05d9\u05e0\u05d5 \u05e0\u05ea\u05de\u05da.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"\u05d4\u05e7\u05d1\u05e6\u05d9\u05dd {0} \u05d5 {1} \u05d0\u05d9\u05e0\u05dd \u05e0\u05ea\u05de\u05db\u05d9\u05dd.",//no i18n
	"crm.attach.upload.sizelimit":"\u05d2\u05d1\u05d5\u05dc \u05d2\u05d5\u05d3\u05dc \u05db\u05d5\u05dc\u05dc",//no i18n
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "\u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea\u05da \u05dc\u05d4\u05e2\u05dc\u05d5\u05ea \u05e2\u05d3 {0} \u05e7\u05d1\u05e6\u05d9\u05dd \u05d1\u05dc\u05d1\u05d3.",
	"crm.file.upload.maxlength.exceeds" : "\u05d1\u05d0\u05e4\u05e9\u05e8\u05d5\u05ea\u05da \u05dc\u05d4\u05e2\u05dc\u05d5\u05ea \u05e7\u05d5\u05d1\u05e5 \u05d0\u05d7\u05d3 \u05d1\u05dc\u05d1\u05d3 \u05d1 {0}."


	
}
